import Ajv from 'ajv/dist/2020'
import HubService from '@/api-services/hub.service'
import NetvsConfig from '../../netvs.config'

export default {
  compile_string(template, variables, string, parent = null) {
    string = string.replaceAll('{{[\\s]*([a-zA-Z0-9_]+)[\\s]*}}', '{{$1}}')
    const tokens_l = string.split('{{')
    const tokens_r = string.split('}}')
    if (tokens_l.length !== tokens_r.length) {
      throw new Error('Malformed template string!', string)
    }
    if (tokens_l.length === 1) {
      return string
    }
    let res = string
    for (let i = 1; i < tokens_l.length; i++) {
      const parts = tokens_l[i].split('}}', 1)
      const token_raw = parts[0]
      const token = token_raw.trim()
      if (Array.isArray(variables[token]) && res === `{{${token_raw}}}`) {
        // In order to support arrays, we need to replace the entire string with the array if the token matches
        if (res.includes(`{{${token_raw}}}`)) {
          window.console.debug('Replacing array token', token, variables[token], 'with parent', parent)
          if (parent && typeof parent === 'string' && parent === '_dict_list') {
            // Special case for tmp_object _dict_list
            const dict_list = []
            for (const elem of variables[token]) {
              dict_list.push({item: elem})
            }
            res = dict_list
          } else {
            res = variables[token]
          }
        }
      } else if (typeof variables[token] === 'boolean' || variables[token] === 'true' || variables[token] === 'false') {
        window.console.debug('Replacing boolean token', token, variables[token])
        if (res.includes(`{{${token_raw}}}`)) {
          res = variables[token] === 'true'
        }
      } else {
        window.console.debug(template)
        window.console.debug('Replacing token', token, 'with', variables[token] || (token in template.variables ? template.variables[token].default : ''))
        res = res.replaceAll(`{{${token_raw}}}`, variables[token] || (token in template.variables ? template.variables[token].default : ''))
      }
    }
    return res
  },
  compile_entity(template, variables, ent, parent) {
    if (ent === null) {
      return null
    }
    if (typeof ent === 'object') {
      return this.compile_object(template, variables, ent, parent)
    } else if (Array.isArray(ent)) {
      return this.compile_array(template, variables, ent, parent)
    } else if (typeof ent === 'string') {
      return this.compile_string(template, variables, ent, parent)
    }
    return ent
  },
  compile_object(template, variables, obj) {
    window.console.debug('compile object', obj)
    for (const key of Object.keys(obj)) {
      obj[key] = this.compile_entity(template, variables, obj[key], key)
    }
    return obj
  },
  compile_array(template, variables, arr) {
    for (let i = 0; i < arr.length; i++) {
      arr[i] = this.compile_entity(template, variables, arr[i])
    }
    return arr
  },
  uuid_when_arr(item, uuid) {
    for (let i = 0; i < item.length; i++) {
      if (typeof item[i] === 'string') { // FIXME: this could be a "real" string and not a dict identifier
        item[i] = `${uuid}_${item[i]}`
      } else if (typeof elem === 'object') {
        item[i] = this.uuid_when_obj(item[i], uuid)
      } else if (Array.isArray(item[i])) {
        item[i] = this.uuid_when_arr(item[i], uuid)
      }
    }
    return item
  },
  uuid_when_obj(item, uuid) {
    for (const elem in item) {
      if (typeof item[elem] === 'string') { // FIXME: this could be a "real" string and not a dict identifier
        item[elem] = `${uuid}_${item[elem]}`
      } else if (typeof item[elem] === 'object') {
        item[elem] = this.uuid_when_obj(item[elem], uuid)
      } else if (Array.isArray(item[elem])) {
        item[elem] = this.uuid_when_arr(item[elem], uuid)
      }
    }
    return item
  },
  uuid_when_cond(item, uuid) {
    window.console.debug('when', item)
    if (typeof item === 'object') {
      return this.uuid_when_obj(item, uuid)
    } else if (Array.isArray(item)) {
      return this.uuid_when_arr(item, uuid)
    }
  },
  uuid_idx(item, uuid) {
    if (typeof item === 'object') {
      for (const key in item) {
        if (key === 'idx') {
          item[key] = `${uuid}${item[key] !== '' ? '_' : ''}${item[key]}`
        } else if (key.endsWith('join_ref')) {
          window.console.debug('join_ref', item[key])
          for (const join_key in item[key]) {
            item[key][`${uuid}${join_key !== '' ? '_' : ''}${join_key}`] = `${item[key][join_key]}`
            delete item[key][join_key]
          }
        } else if (key === 'when') {
          item[key] = this.uuid_when_cond(item[key], uuid)
        } else {
          item[key] = this.uuid_idx(item[key], uuid)
        }
      }
    } else if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        item[i] = this.uuid_idx(item[i], uuid)
      }
    }
    return item
  },
  compile_transaction(template, variables, uuid) {
    const ta = this.compile_entity(template, variables, template.transaction)
    return this.uuid_idx(ta, uuid)
  },
  async validate_template(template) {
    const ajv = new Ajv()
    const validate = ajv.compile((await HubService.getSchema('version_1.schema.json')).data)
    let val_result = validate(template)
    let errs = validate.errors
    if (template.target_api !== NetvsConfig.NETDB_API_VERSION) {
      if (val_result) {
        val_result = false
        errs = []
      }
      errs.push({message: `Unspported API version. Expected ${NetvsConfig.NETDB_API_VERSION}, found ${template.target_api}`})
    }
    return {status: val_result, errors: errs}
  }
}
