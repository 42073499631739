<template>
  <div class="net-filter-direct d-print-none">
    <b-input-group class="shadow">
      <b-input-group-prepend>
        <b-input-group-text>
          <netvs-icon icon="filter"></netvs-icon>
        </b-input-group-text>
      </b-input-group-prepend>
      <b-input debounce="300" :value="value" @update="$emit('input', $event)" class="search form-control"
               :placeholder="$t('components.filter_input.filter')"
               autofocus/>
      <b-input-group-append v-if="allow_direct">
        <b-button :title="$t('components.filter_input.direct_dialing')" variant="outline-secondary" type="submit"
                  class="mr-0">
          <netvs-icon icon="navigate"></netvs-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'FilterInput',
  props: {
    allow_direct: {
      default() {
        return false
      }
    },
    value: {
      default() {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables.scss';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
.net-filter-direct {
  top: $navbar-height + 10px;
  position: sticky;
  margin: 20px 0;
  z-index: 420;
}
</style>
