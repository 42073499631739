import Vue from 'vue'

import './icons-solid'
import './icons-regular'
import './icons-brand'
import './icons-duotone'
import './icons-thin'
import './icons-light'
import NETVSIcon from '@/icons/NETVSIcon.vue'

Vue.component('netvs-icon', NETVSIcon)
