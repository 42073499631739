import {library} from '@fortawesome/fontawesome-svg-core'

import {
  faPenToSquare,
  faCheckSquare,
  faLifeRing,
  faPaperPlane,
  faBuilding,
  faBlockBrickFire,
  faTrashCan,
  faFaceSadSweat,
  faFaceSmile,
  faEnvelopeCircleCheck,
  faArrowProgress,
  faLayerGroup,
  faWandMagicSparkles,
  faPortalExit,
  faPortalEnter,
  faTrashList,
  faDeleteLeft,
  faUserLock,
  faTypewriter,
  faGlobePointer,
  faConveyorBeltArm,
  faCircleNodes,
  faSkull,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faConveyorBeltArm,
  faPenToSquare,
  faCheckSquare,
  faLifeRing,
  faPaperPlane,
  faBuilding,
  faBlockBrickFire,
  faTrashCan,
  faFaceSadSweat,
  faFaceSmile,
  faEnvelopeCircleCheck,
  faArrowProgress,
  faLayerGroup,
  faWandMagicSparkles,
  faPortalExit,
  faPortalEnter,
  faTrashList,
  faDeleteLeft,
  faUserLock,
  faTypewriter,
  faGlobePointer,
  faCircleNodes,
  faSkull,
)
