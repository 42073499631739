<template>
    <div id="sidebar" style="height: 100%">
        <TransactionList v-if="$store.state.ta_list" style="height: 100%; overflow-y: auto"/>
    </div>
</template>

<script>
import TransactionList from './TransactionList'
export default {
  name: 'LeftSidebar',
  components: { TransactionList }
}
</script>

<style scoped>

</style>
