import TransactionUtil from '@/util/transactionutil'

export default {
  async check_input(vm, system, object, fn, field, input) {
    return this.check_input_by_datatype(vm, (await TransactionUtil.getObjectMetadata(vm, system + '.' + object)).object_functions[fn].parameters[field].data_type, input)
  },
  async check_input_by_datatype(vm, dataTypeName, input) {
    try {
      const dataType = vm.$store.state.db_editor_data_type_cache[dataTypeName]
      if (Number.isInteger(dataType.text_length) && input.length > dataType.text_length) {
        window.console.debug('typechecker: reject because of length')
        return false
      }
      return true
    } catch (e) {
      window.console.warn('Type check failed:')
      window.console.warn(e)
      return false
    }
  }
}
