import Axios from 'axios'

export default {
  getIndex() {
    return Axios.get('/api/hub/index.json?' + Math.random().toString())
  },
  getTemplate(template_file) {
    return Axios.get('api/hub/json_templates/' + template_file + '?' + Math.random().toString())
  },
  getSchema(schema_file) {
    return Axios.get('api/hub/schemas/' + schema_file + '?' + Math.random().toString())
  }
}
