import {v4 as uuidv4} from 'uuid'
import ApiUtil from '@/util/apiutil'
import TransactionService from '@/api-services/transaction.service'

export default {
  getSysName(fq_name) {
    return fq_name.split('.')[0]
  },
  getObjName(fq_name) {
    return fq_name.split('.')[1]
  },
  getObjFunction(fq_name) {
    return fq_name.split('.')[2]
  },
  dictifyAndEnrichTa(ta, state) {
    const names = {}
    const ta_additional = []
    for (let i = 0; i < ta.length; i++) {
      const fq_name = this.getObjName(ta[i].name)
      const func_name = this.getObjFunction(ta[i].name)
      const res_name = 'idx' in ta[i] ? ta[i].idx : fq_name + '_' + func_name
      if (res_name in names) {
        throw Error('Ambigous TA name! Please specify idx! Affected index: ' + i + ' with (compiled) name ' + res_name)
      }
      names[res_name] = ta[i]
      ta[i].idx = res_name
      if (state.ot_lang_attr_def && `${this.getSysName(ta[i].name)}.${fq_name}` in state.ot_lang_attr_def) {
        const join = {}
        join[res_name] = null
        ta_additional.push({idx: `__lang_${res_name}`, name: 'cntl.ot_lang_attr_val.list', inner_join_ref: join})
      }
    }
    ta.push(...ta_additional)
    return ta
  },
  function2variant(function_name, outline = false) {
    const prefix = outline ? 'outline-' : ''
    switch (function_name) {
      case 'create':
        return prefix + 'success'
      case 'connect':
        return prefix + 'success'
      case 'disconnect':
        return prefix + 'danger'
      case 'delete':
        return prefix + 'danger'
      case 'update':
      case 'bulk_update':
        return prefix + 'primary'
      default:
        return prefix + 'default'
    }
  },
  functionWithObject2text(function_name, object_name) {
    switch (function_name) {
      case 'connect':
        return this.$t('system.utils.connect_template', {object: object_name})
      case 'disconnect':
        return this.$t('system.utils.disconnect_template', {object: object_name})
      case 'create':
        return this.$t('system.utils.create_template', {object: object_name})
      case 'delete':
        return this.$t('system.utils.delete_template', {object: object_name})
      case 'update':
        return this.$t('system.utils.update_template', {object: object_name})
      case 'bulk_update':
        return this.$t('system.utils.bulk_update_template', {object: object_name})
      default:
        return function_name
    }
  },
  function2text(function_name, count = 1) {
    if (count > 1) {
      return this.$t('system.utils.multiple', {action: this.function2text(function_name)})
    }
    switch (function_name) {
      case 'create':
        return this.$t('system.utils.create')
      case 'delete':
        return this.$t('system.utils.delete')
      case 'update':
        return this.$t('system.utils.update')
      case 'bulk_update':
        return this.$t('system.utils.bulk_update')
      default:
        return function_name
    }
  },
  nullify(data) {
    if (data === '__NULL') {
      return null
    }
    return data
  },
  filter_unchanged_data(d1, d2) {
    const self = this
    if (!d2) {
      return d1
    }
    return Object.keys(d1).reduce(function (filtered, key) {
      if (!(key in d2) || d1[key] !== d2[key]) filtered[key] = self.nullify(d1[key])
      return filtered
    }, {})
  },
  filter_old_data(params_list, old_data) {
    return Object.keys(old_data).reduce(function (filtered, key) {
      if (key in params_list && 'old' in params_list[key]) filtered[key] = old_data[key]
      return filtered
    }, {})
  },
  remove_empty_data(params_list, data) {
    return Object.keys(data).reduce(function (filtered, key) {
      if (!(data[key] === '')) filtered[key] = data[key]
      return filtered
    }, {})
  },
  remove_non_data_data(params_list, data) {
    const dat = Object.keys(data).reduce(function (filtered, key) {
      if (data[key] !== '' && key in params_list) filtered[key] = data[key]
      return filtered
    }, {})
    window.console.debug('non data filtered data', dat)
    return dat
  },
  cast_to_json_type(state, params_list, data) {
    const self = this
    return Object.keys(data).reduce(function (filtered, key) {
      const dt = self.getDataType(state, params_list[key].data_type)
      switch (dt.json_name) {
        case 'boolean':
          if (data[key] === '' || data[key] === null) {
            filtered[key] = null
          } else {
            filtered[key] = data[key] === 'true'
          }
          break
        case 'number':
          if (data[key] === '' || data[key] === null) {
            filtered[key] = null
          } else {
            filtered[key] = Number(data[key])
          }
          break
        default:
          filtered[key] = data[key]
      }
      return filtered
    }, {})
  },
  generateDeleteElement(object_fq_name, params_list, old_data, title) {
    const ta = {}
    ta.variant = 'danger'
    ta.ta_type = 'db_editor_simple'
    ta.content = {}
    ta.content.parameters = {
      new: {},
      old: Object.keys(old_data).reduce(function (filtered, key) {
        if (params_list.old.includes(key)) filtered[key] = old_data[key]
        return filtered
      }, {})
    }
    ta.uuid = uuidv4()
    ta.content.object_fq_name = object_fq_name
    ta.content.object_function = 'delete'
    ta.content.object_functions = null
    ta.content.object_title = title
    return ta
  },
  async validateObjectFQName(vm, object_fq_name) {
    const parts = object_fq_name.split('.')
    const system = parts[0]
    const name = parts[1]
    const object_result = TransactionService.execute(vm.$store.state,
      [
        {
          name: 'wapi.object_type.list',
          old: {
            name_list: [name],
            system_list: [system]
          }
        },
        {
          name: 'wapi.object_type_lang_dict.list',
          old: {
            name_list: [name],
            system_list: [system]
          }
        },
        {
          name: 'wapi.object_type_attr_lang_dict.list',
          old: {
            object_type_list: [name],
            system_list: [system]
          }
        },
        {
          name: 'wapi.function.list',
          old: {
            object_type_list: [name],
            system_list: [system]
          }
        }
      ]
    )
    try {
      const object_definition = (await object_result).data
      if (object_definition.object_type_list.length !== 1) {
        return false
      }
      for (const object_definition_lang_attr in object_definition.object_type_lang_dict_list[0].language_dict) {
        object_definition.object_type_list[0][object_definition_lang_attr] = object_definition.object_type_lang_dict_list[0].language_dict[object_definition_lang_attr]
      }
      const lang_by_fq_name = ApiUtil.dict_by_value_of_array(object_definition.object_type_attr_lang_dict_list, 'fq_name')
      // Enrich function definitions with language attributes
      const obj_functions = object_definition.function_list
      for (const f of obj_functions) {
        for (const func_param_name in f.parameters) {
          const lang = lang_by_fq_name[`${system}.${name}.${func_param_name}`].language_dict
          for (const object_function_lang_attr in lang) {
            f.parameters[func_param_name][object_function_lang_attr] = lang[object_function_lang_attr]
          }
        }
      }
      // Enrich object definition with language attributes
      for (const obj_attr_name in object_definition.object_type_list[0].attributes) {
        const lang = lang_by_fq_name[`${system}.${name}.${obj_attr_name}`].language_dict
        for (const object_function_lang_attr in lang) {
          object_definition.object_type_list[0].attributes[obj_attr_name][object_function_lang_attr] = lang[object_function_lang_attr]
        }
      }
      return {
        object_definition: object_definition.object_type_list[0],
        object_functions: ApiUtil.dict_by_value_of_array(obj_functions, 'name')
      }
    } catch (e) {
      window.console.debug('Silenced error in validateObjectFQName', e)
      return false
    }
  },
  getDataType(state, data_type) {
    return state.db_editor_data_type_cache[data_type]
  },
  async getObjectMetadata(vm, object_fq_name) {
    const res = {}
    if (!('version' in vm.$store.state.db_editor_cache) || vm.$store.state.current_api_version !== vm.$store.state.db_editor_cache.version) {
      vm.$store.commit('clearDBEditorData')
    }
    if (object_fq_name in vm.$store.state.db_editor_cache) {
      const entry = vm.$store.state.db_editor_cache[object_fq_name]
      res.object_definition = entry.object_definition
      res.object_functions = entry.object_functions
      res.object_definition_referencing_by_attribute = entry.object_definition_referencing_by_attribute
      res.object_definition_reference_names_by_attribute = entry.object_definition_reference_names_by_attribute
      return res
    }
    const data = await this.validateObjectFQName(vm, object_fq_name)
    if (data === false) {
      return null
    } else {
      res.object_definition = data.object_definition
      res.object_definition_referencing_by_attribute = {}
      res.object_definition_reference_names_by_attribute = {}
      for (const ref in res.object_definition.referencing) {
        for (const attrib of res.object_definition.referencing[ref].attributes) {
          res.object_definition_referencing_by_attribute[attrib] = res.object_definition.referencing[ref].references
          res.object_definition_reference_names_by_attribute[attrib] = ref
        }
      }
      res.object_functions = data.object_functions
      res.object_fq_name = object_fq_name
      vm.$store.commit('cacheDBEditorData', {
        object_fq_name: res.object_fq_name,
        object_definition: res.object_definition,
        object_functions: res.object_functions,
        object_definition_referencing_by_attribute: res.object_definition_referencing_by_attribute,
        object_definition_reference_names_by_attribute: res.object_definition_reference_names_by_attribute
      })
      return res
    }
  }
}
