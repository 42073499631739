<template>
  <b-container style="padding: 0 11px 0 0">
    <b-form-row style="margin-bottom: -15px">
      <b-col>
        <netvs-icon v-if="icon" :icon="icon"/>
        <legend class="col-form-label">{{ variable.friendly_name[$store.state.locale] }}</legend>
      </b-col>
      <b-col cols="1">
        <b-button variant="outline-success" @click="true_values.push('')">
          <netvs-icon icon="add_item"></netvs-icon>
        </b-button>
      </b-col>
    </b-form-row>
    <b-form-row style="margin-bottom: -5px" v-for="(v, index) in true_values" :key="v">
      <b-col>
        <JSONTemplateDataEditField :variable_id="variable_id" :value="v" :variable="variable" :show_label="false"
                                   :show_description="(index+1) === true_values.length"/>
      </b-col>
      <b-col cols="1">
        <b-button variant="outline-danger" @click="true_values.splice(index, 1)" :disabled="true_values.length == 1">
          <netvs-icon icon="delete"></netvs-icon>
        </b-button>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import JSONTemplateDataEditField from '@/components/db-editor/JSONTemplateDataEditField.vue'

export default {
  name: 'JSONTemplateDataEditFieldList',
  components: { JSONTemplateDataEditField },
  methods: {},
  data: function () {
    return {
      true_values: this.value
    }
  },
  watch: {
    value(val) {
      if (typeof val === 'string') {
        this.true_values = [val]
      }
      this.true_values = val
    }
  },
  props: {
    icon: {
      required: false,
      type: String,
      default() {
        return null
      }
    },
    variable: {
      type: Object,
      required: true
    },
    variable_id: {
      type: String,
      required: true
    },
    value: {
      default() {
        return ['']
      }
    }
  }
}
</script>

<style scoped>

</style>
