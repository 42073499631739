import Axios from 'axios'
import TransactionService from '@/api-services/transaction.service'
import MaintService from '@/api-services.gen/wapi.maint_state'
import NetvsConfig from '../../netvs.config'

const RESOURCE_NAME = '/api/system/info'

export default {
  getAll () {
    return Axios.get(RESOURCE_NAME)
  },
  getSessionInfo(config) {
    const ta = [
      { name: 'cntl.mgr.list', old: { is_self: true } },
      { name: 'cntl.role.list', old: { is_directly_assigned: true, is_role: true, sorting_params_list: ['position'] } },
      { name: 'cntl.role.list', idx: 'permission_list', old: { is_role: false }, inner_join_ref: { role_list: 'api_func_cntl_role_hierarchy_gfk_list_is_superset' } },
      { name: 'cntl.ot_lang_attr_val.list', idx: 'permission_descr', inner_join_ref: {permission_list: null}},
      { name: 'cntl.ot_lang_attr_def.list' },
      { name: 'cntl.mgr2ou.list', old: { fetch_limit: 1, is_own: true }},
    ]
    return TransactionService.execute(config, ta)
  },
  getAPIVersionIndex() {
    return Axios.get(`${NetvsConfig.NETDB_API_BASE_URL}/${NetvsConfig.NETDB_API_VERSION}/wapi/version/list`)
  },
  getFrontendVersion() {
    return Axios.get('/version.json?' + Math.random().toString())
  },
  getAlerts() {
    return Axios.get('/api/alerts.json?' + Math.random().toString())
  },
  getMaintenance() {
    return MaintService.list({ netdb_axios_config: undefined }, {sorting_params_list: ['is_active DESC', 'ts_begin ASC']})
  }
}
