<template>
  <b-input-group class="copy-field">
    <b-form-textarea v-if="multiline" rows="10" :id="'text-input' + id" ref="textInput" :value="text"
                     :style="{height: height_override}"
                     readonly
                     :class="`border-${variant} text-${variant} invisible-scrollbar ${code?'text-monospace':''} ${nowrap?'nowrap':''}`"/>
    <b-form-input v-else :id="'text-input' + id" ref="textInput" :value="text"
                  readonly
                  :class="`border-${variant} text-${variant} invisible-scrollbar ${code?'text-monospace':''}`"/>
    <b-tooltip :target="'text-input' + id" :show.sync="text_copied" :disabled="!text_copied" placement="bottom"
               :variant="variant" :class="`border-${variant} text-${variant}`">
      {{ $t('components.copy_field.copied') }}.
    </b-tooltip>
    <b-input-group-append>
      <b-button :variant="variant" @click="copyText">
        <netvs-icon icon="copy"></netvs-icon>
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  name: 'CopyField',
  props: {
    text: String,
    variant: {
      type: String,
      default: 'primary'
    },
    multiline: {
      type: Boolean,
      default: false
    },
    code: {
      type: Boolean,
      default: false
    },
    nowrap: {
      type: Boolean,
      default: false
    },
    height_override: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      id: null,
      text_copied: false
    }
  },
  methods: {
    copyText() {
      // https://www.w3schools.com/howto/howto_js_copy_clipboard.asp
      const text_input = this.$refs.textInput
      text_input.select()
      text_input.setSelectionRange(0, 99999) // For mobile devices
      document.execCommand('copy')
      this.text_copied = true
    }
  },
  mounted() {
    this.id = this._uid
  }
}
</script>
