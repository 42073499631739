// Heavily modified version of
// https://github.com/anthonygore/vuex-undo-redo

export default {
  computed: {
    canRedo() {
      return this.$store.state.undone.length !== 0 && !this.$store.state.executing_transaction
    },
    canUndo() {
      return this.$store.state.done.length !== 0 && !this.$store.state.executing_transaction
    }
  },
  methods: {
    redo() {
      const commit = this.$store.state.undone[this.$store.state.undone.length - 1]
      this.$store.commit('popUndoRedoUndone')
      this.$store.commit('setUndoRedoNewMutation', false)
      switch (typeof commit.payload) {
        case 'object':
          this.$store.commit(`${commit.type}`, Object.assign({}, commit.payload))
          break
        default:
          this.$store.commit(`${commit.type}`, commit.payload)
      }
      this.$store.commit('setUndoRedoNewMutation', true)
    },
    undo() {
      this.$store.commit('pushUndoRedoUndone', this.$store.state.done[this.$store.state.done.length - 1])
      this.$store.commit('popUndoRedoDone')
      this.$store.commit('setUndoRedoNewMutation', false)
      this.$store.commit('emptyState')
      this.$store.state.done.forEach(mutation => {
        switch (typeof mutation.payload) {
          case 'object':
            this.$store.commit(mutation.type, Object.assign({}, mutation.payload))
            break
          default:
            this.$store.commit(mutation.type, mutation.payload)
        }
        this.$store.commit('popUndoRedoDone')
      })
      this.$store.commit('setUndoRedoNewMutation', true)
    }
  }
}
