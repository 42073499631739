<!-- https://github.com/alexurquhart/vue-bootstrap-typeahead
Copyright 2018 Alex Urquhart

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
-->

<!--
For usage example, refer to PatchRequestForm.vue
-->
<template>
  <div>
    <div :class="sizeClasses">
      <div ref="prependDiv" v-if="$slots.prepend || prepend" class="input-group-prepend">
        <slot name="prepend">
          <span class="input-group-text">{{ prepend }}</span>
        </slot>
      </div>
      <b-form-input
          ref="input"
          type="search"
          :class="`form-control ${inputClass}`"
          :placeholder="placeholder"
          :aria-label="placeholder"
          :value="value"
          :disabled="disabled"
          @focus="isFocused = true"
          @blur="handleBlur"
          @input="handleInput"
          autocomplete="off"
          :state="state"
          :autofocus="autofocus"
          :debounce="debounce"
      />
      <div class="loading-indicator-container">
        <b-spinner variant="primary" v-if="loading" small/>
      </div>
      <b-input-group-append v-if="show_clear">
        <b-button :disabled="disabled" variant="outline-secondary" @click="clear();focus()" tabindex="-1">
          <netvs-icon icon="clear"/>
        </b-button>
      </b-input-group-append>
      <div v-if="$slots.append || append" class="input-group-append">
        <slot name="append">
          <span class="input-group-text">{{ append }}</span>
        </slot>
      </div>
    </div>
    <TypeaheadList
        class="vbt-autcomplete-list"
        ref="list"
        v-show="isFocused && data && (data.length > 0 || minMatchingChars <= 0)"
        :query="value?value:undefined"
        :data="formattedData"
        :background-variant="backgroundVariant"
        :text-variant="textVariant"
        :maxMatches="maxMatches"
        :minMatchingChars="minMatchingChars"
        :compact="compact"
        @hit="handleHit"
    >
      <!-- pass down all scoped slots -->
      <template v-for="(slot, slotName) in $scopedSlots" :slot="slotName" slot-scope="{ data, htmlText }">
        <slot :name="slotName" v-bind="{ data, htmlText }"></slot>
      </template>
      <!-- below is the right solution, however if the user does not provide a scoped slot, vue will still set $scopedSlots.suggestion to a blank scope
      <template v-if="$scopedSlots.suggestion" slot="suggestion" slot-scope="{ data, htmlText }">
        <slot name="suggestion" v-bind="{ data, htmlText }" />
      </template>-->
    </TypeaheadList>
  </div>
</template>

<script>
import TypeaheadList from './TypeaheadList.vue'
import ResizeObserver from 'resize-observer-polyfill'

export default {
  name: 'TypeaheadComponent',

  components: {
    TypeaheadList
  },

  props: {
    size: {
      type: String,
      default: null,
      validator: size => ['lg', 'sm'].indexOf(size) > -1
    },
    value: {
      type: String
    },
    data: {
      type: Array,
      validator: d => d instanceof Array
      // A list of:
      // Strings (those will be the option names and hit values)
      // Objects in the form { text: '...', icon: 'netvs-icon-name' }
      // Objects in any form, with the serializer prop set to extract the text
    },
    serializer: {
      type: Function,
      default: undefined,
      validator: d => d instanceof Function
    },
    backgroundVariant: String,
    textVariant: String,
    inputClass: {
      type: String,
      default: ''
    },
    maxMatches: {
      type: Number,
      default: 10
    },
    minMatchingChars: {
      type: Number,
      default: 2
    },
    placeholder: String,
    prepend: String,
    append: String,
    disabled: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: true
    },
    sortFunction: { // Sort item.data for the original object or item.text for the serialized string
      type: Function,
      default: (a, b) => a.text.localeCompare(b.text)
    },
    compact: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    show_clear: {
      type: Boolean,
      default: true
    },
    state: {
      type: Boolean,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    debounce: {
      type: Number,
      default: 0
    },
    form_eval_mode: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    sizeClasses() {
      return this.size ? `input-group input-group-${this.size}` : 'input-group'
    },

    formattedData() {
      if (!this.data || !(this.data instanceof Array)) {
        return []
      }

      const serializer = this.serializer || (d => d)

      return this.data.map((d, i) => {
        if (typeof d === 'string' || this.serializer) {
          return {
            id: i,
            data: d,
            text: serializer(d)
          }
        } else {
          d.id = i
          d.data = d.text
          d.text = serializer(d.text)
          return d
        }
      }).sort((a, b) => {
        if (this.sort) {
          return this.sortFunction(a, b)
        }
        return 0
      })
    }
  },

  methods: {
    resizeList(el) {
      const rect = el.getBoundingClientRect()
      const listStyle = this.$refs.list.$el.style

      // Set the width of the list on resize
      listStyle.width = rect.width + 'px'

      // Set the margin when the prepend prop or slot is populated
      // (setting the "left" CSS property doesn't work)
      if (this.$refs.prependDiv) {
        const prependRect = this.$refs.prependDiv.getBoundingClientRect()
        listStyle.marginLeft = prependRect.width + 'px'
      }
    },

    handleHit(evt) {
      if (typeof this.value !== 'undefined' && this.form_eval_mode) {
        this.$emit('input', evt.text)
      }

      this.$emit('select', evt.data)
      this.$refs.input.blur()
      this.isFocused = false
    },

    handleBlur(event) {
      const target = event.relatedTarget
      if (target === this.$refs.list.$el ||
          this.$refs.list.$el.contains(target) ||
          this.$refs.input.$el.contains(document.activeElement) ||
          this.$refs.list.$el.matches(':hover')) return
      this.isFocused = false
    },

    handleInput(newValue) {
      // If v-model is being used, emit an input event
      if (typeof this.value !== 'undefined') {
        this.$emit('input', newValue)
      }
    },

    clear() {
      this.$emit('input', '')
      this.$emit('select', null)
    },

    focus() {
      this.$refs.input.focus()
    },
    blur() {
      this.$refs.input.blur()
    }
  },
  data() {
    return {
      isFocused: false
    }
  },
  mounted() {
    this.$_ro = new ResizeObserver(e => {
      this.resizeList(this.$refs.input.$el)
    })
    this.$_ro.observe(this.$refs.input.$el)
    this.$_ro.observe(this.$refs.list.$el)
  },

  beforeDestroy() {
    this.$_ro.disconnect()
  }
}
</script>

<style scoped>
.vbt-autcomplete-list {
  padding-top: 5px;
  position: absolute;
  max-height: 350px;
  overflow-y: auto;
  z-index: 999;
}

.loading-indicator-container {
  position: relative;
  width: 0;
  margin: auto 0;
}

.loading-indicator-container > span {
  position: relative;
  right: 30px;
  z-index: 3;
}
</style>
