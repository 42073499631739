<template>
  <div class="paginator">
    <FilterInput v-if="!hideFilter" v-model="filterValue"></FilterInput>
    <Paginator :current-page="current_page" :per-page="items_per_page" @current-page="current_page = $event"
               @per-page="setPerPage($event)" :total-rows="filtered_and_sorted_items.length" :block="block"
               :per-page-options="itemsPerPageOptions"
               v-if="items && filtered_and_sorted_items.length > 0 && !hideTopPagination"/>

    <div class="pagination_container" v-if="items">
      <slot name="content_skeleton_item" v-if="!items || items.length === 0"></slot>
      <slot name="item" v-else
            v-for="(item, index) in sliced_items"
            :item="item" :index="index"/>
    </div>
    <div v-if="!items || filtered_and_sorted_items.length === 0"
         class="font-italic text-center my-3">
      <span class="font-italic">{{ $t(noItemsTranslationKey) }}</span><br/>
    </div>

    <Paginator :current-page="current_page" :per-page="items_per_page" @current-page="current_page = $event"
               @per-page="setPerPage($event)" :total-rows="filtered_and_sorted_items.length" :block="block"
               :per-page-options="itemsPerPageOptions"
               v-if="items && filtered_and_sorted_items.length > 0"/>
  </div>
</template>

<script>
import FilterInput from '@/components/FilterInput'
import Paginator from '@/components/Paginator'

export default {
  name: 'PaginatorList',
  components: { FilterInput, Paginator },
  data() {
    return {
      current_page: 1,
      items_per_page: 25,
      filterValue: ''
    }
  },
  props: {
    items: {
      required: true,
      default() {
        return null
      }
    },
    initialItemsPerPage: {
      default() {
        return 25
      }
    },
    itemsPerPageOptions: {
      default() {
        return [
          5,
          25,
          50,
          100,
          500,
          { value: 0, translation_key: 'components.paginator.all' }
        ]
      }
    },
    block: {
      type: Boolean,
      default() {
        return false
      }
    },
    noItemsTranslationKey: {
      default() {
        return 'components.paginator_list.no_entries'
      }
    },
    hideTopPagination: {
      default() {
        return false
      }
    },
    hideFilter: {
      default() {
        return false
      }
    },
    filterFunction: {
      type: Function,
      default() {
        return function (item, term) {
          if (typeof term === 'string') {
            term = term.toLowerCase()
          }
          for (const v of Object.values(item)) {
            if (typeof v === 'string') {
              if (v.toLowerCase().includes(term)) {
                return true
              }
            }
          }
          return false
        }
      }
    },
    sortFunction: {
      type: Function,
      default() {
        return null
      }
    }
  },
  watch: {
    items(newVal, oldVal) {
      if (newVal) this.current_page = Math.min(this.current_page, Math.floor(newVal.length / this.items_per_page) + 1) || 1
    }
  },
  computed: {
    filtered_and_sorted_items() {
      if (!this.items) return []
      const filtered_items = this.filterValue === '' ? this.items : this.items.filter(x => this.filterFunction()(x, this.filterValue))
      return this.sortFunction ? filtered_items.sort(this.sortFunction) : filtered_items
    },
    sliced_items() {
      if (this.items_per_page === 0) {
        return this.filtered_and_sorted_items
      }
      return this.filtered_and_sorted_items.slice((this.current_page - 1) * this.items_per_page, Math.min(this.items.length, this.current_page * this.items_per_page))
    }
  },
  methods: {
    setPerPage(itemsPerPage) {
      this.items_per_page = itemsPerPage
    }
  }
}
</script>

<style scoped>

</style>
