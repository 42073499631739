// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'language_dict']}
  },
  create (config, {language_dict_new}) {
    const params = { 'new': { 'language_dict': language_dict_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/wapi/data_type/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'language_dict'], 'new': [ ]}
  },
  delete (config, {language_dict_old}) {
    const params = { 'new': { }, 'old': { 'language_dict': language_dict_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/wapi/data_type/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { json_name, fetch_limit, text_length, fetch_offset, language_dict, filter_params_dict, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'json_name': json_name, 'fetch_limit': fetch_limit, 'text_length': text_length, 'fetch_offset': fetch_offset, 'language_dict': language_dict, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/wapi/data_type/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'language_dict'], 'new': [ 'language_dict']}
  },
  update (config, {language_dict_old, language_dict_new}) {
    const params = { 'new': { 'language_dict': language_dict_new}, 'old': { 'language_dict': language_dict_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/wapi/data_type/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
