<template>
  <b-overlay class="d-flex flex-column" spinner-variant="primary" opacity="0.6"
             :show="$store.state.executing_transaction">
    <div class="transaction-list invisible-scrollbar p-3">
      <b-button-toolbar justify>
        <b-button-group class="mx-auto shadow">
          <b-button-group v-b-tooltip.hover :title="$t('components.transaction_list.undo')">
            <b-button :disabled="!canUndo" @click="undo">
              <netvs-icon icon="undo"></netvs-icon>
            </b-button>
          </b-button-group>
          <b-button-group v-b-tooltip.hover :title="$t('components.transaction_list.restore')">
            <b-button :disabled="!canRedo" @click="redo">
              <netvs-icon icon="redo"></netvs-icon>
            </b-button>
          </b-button-group>
        </b-button-group>
      </b-button-toolbar>
      <hr/>
      <div v-if="$store.state.ta_list === null || $store.state.ta_list.length === 0"
           class="font-italic text-center mt-3">
        <span class="font-italic">{{ $t('components.transaction_list.no_actions_planned') }}</span><br/>
        <b-button v-b-modal.import_ta_from_file variant="link">
          <netvs-icon icon="import"></netvs-icon>
          {{ $t('components.transaction_list.import_transaction_list') }}
        </b-button>
      </div>
      <Draggable v-else @change="drag_change" :value="$store.state.ta_list" @start="drag=true" @end="drag=false"
                 handle=".handle">
        <b-card v-for="(element, index) in $store.state.ta_list" :key="element.id"
                :border-variant="element.variant" class="mb-3 shadow fade-in" no-body>
          <div class="element-number handle"
               :class="`badge badge-${element.variant}`">
            {{ index + 1 }}
            <!-- TODO: Mouse feedback on click -->
            <div class="move-icons">
              <netvs-icon class="move-icon"
                          icon="move_up"
                          @click="move(index, index - 1)"></netvs-icon>
              <netvs-icon class="move-icon"
                          icon="move_down"
                          @click="move(index, index + 1)"></netvs-icon>
            </div>
          </div>
          <b-card-body class="element-content" v-if="element.ta_type && element.ta_type === 'json_template'">
            <netvs-icon icon="community_content" :id="'hub_content-'+element.uuid"/>
            <b-tooltip placement="bottom" :target="'hub_content-'+element.uuid"
                       triggers="hover" variant="secondary" container="sidebar">
              {{ $t('system.netvs_hub') }}
            </b-tooltip>
            {{
              compile_template_string(element.content.template, element.content.variables, element.content.template.list_display_name[$store.state.locale] || element.content.template.list_display_name['de'])
            }}
            <TACardErrorDisplay
                v-if="$store.state.transaction_result && $store.state.transaction_result.uuid === element.uuid"/>
            <b-button-group class="d-flex">
              <b-button
                  :disabled="bundle_should_edit || should_edit || $store.state.executing_transaction || element.content.object_function == 'delete'"
                  variant="outline-primary"
                  :id="'button-edit-action-' + index"
                  @click="editElement(element)">
                <netvs-icon icon="edit"></netvs-icon>
              </b-button>
              <b-tooltip placement="bottom" :target="'button-edit-action-' + index"
                         triggers="hover" variant="primary" container="sidebar">
                {{ $t('components.transaction_list.edit_action') }}
              </b-tooltip>
              <b-button :disabled="bundle_should_edit || should_edit || $store.state.executing_transaction"
                        variant="outline-danger"
                        :id="'button-delete-action-' + index"
                        @click="$store.commit('removeTransactionElement', element)">
                <netvs-icon icon="delete_transaction_element"></netvs-icon>
              </b-button>
              <b-tooltip placement="bottom" :target="'button-delete-action-' + index"
                         triggers="hover" variant="danger" container="sidebar">
                {{ $t('components.transaction_list.delete_action') }}
              </b-tooltip>
            </b-button-group>
          </b-card-body>
          <b-card-body class="element-content"
                       v-if="element.ta_type === 'db_editor_simple' || element.ta_type.startsWith('db_editor_bundle_')">
            <netvs-icon v-if="element.ta_type === 'db_editor_simple'" :id="'simple_content-'+element.uuid"
                        icon="transaction_api_object"/>
            <b-tooltip placement="bottom" :target="'simple_content-'+element.uuid"
                       triggers="hover" variant="secondary" container="sidebar">
              {{ $t('components.transaction_list.simple_content') }}
            </b-tooltip>
            <netvs-icon v-if="element.ta_type.startsWith('db_editor_bundle_')" :id="'bundle_content-'+element.uuid"
                        icon="transaction_bundle"/>
            <b-tooltip placement="bottom" :target="'bundle_content-'+element.uuid"
                       triggers="hover" variant="secondary" container="sidebar">
              {{ $t('components.transaction_list.bundle_content') }}
            </b-tooltip>
            <netvs-icon v-if="element.content.show_result" icon="transaction_returning"/>
            {{ functionWithObject2text(element.content.object_function, element.content.object_fq_name) }}:<br/>
            <span v-if="element.content.object_title">{{ element.content.object_title }}</span>
            <ul>
              <li v-for="field in element.content.preview_fields" v-bind:key="field">
                {{ $t_netdb(element.content.func_descr[field].description_global_scope) }}:
                <span v-if="element.content.object_function=='update'">
                  <br/>
                  <span v-if="element.content.parameters.old[field]"><s><code
                      class="text-danger">{{ element.content.parameters.old[field] }}</code></s><br/></span>
                    <code v-if="element.content.parameters.new[field] !== '__NULL'"
                          class="text-success">{{ element.content.parameters.new[field] }}</code>
                    <code v-else class="text-success">({{ $t('system.null') }})</code>
                </span>
                <span v-else-if="element.content.parameters.new[field] === '__NULL'">({{ $t('system.null') }})</span>
                <code v-else>{{ element.content.parameters.new[field] }}</code>
              </li>
            </ul>
            <TACardErrorDisplay
                v-if="$store.state.transaction_result && $store.state.transaction_result.uuid === element.uuid"/>
            <b-button-group class="d-flex">
              <b-button
                  :disabled="should_edit || bundle_should_edit || $store.state.executing_transaction || element.content.object_function == 'delete'"
                  variant="outline-primary"
                  :id="'button-edit-action-' + index"
                  @click="editElement(element)">
                <netvs-icon icon="edit"></netvs-icon>
              </b-button>
              <b-tooltip placement="bottom" :target="'button-edit-action-' + index"
                         triggers="hover" variant="primary" container="sidebar">
                {{ $t('components.transaction_list.edit_action') }}
              </b-tooltip>
              <b-button :disabled="should_edit || bundle_should_edit|| $store.state.executing_transaction"
                        variant="outline-danger"
                        :id="'button-delete-action-' + index"
                        @click="$store.commit('removeTransactionElement', element)">
                <netvs-icon icon="delete_transaction_element"></netvs-icon>
              </b-button>
              <b-tooltip placement="bottom" :target="'button-delete-action-' + index"
                         triggers="hover" variant="danger" container="sidebar">
                {{ $t('components.transaction_list.delete_action') }}
              </b-tooltip>
            </b-button-group>
          </b-card-body>
        </b-card>
      </Draggable>
      <hr/>
      <b-button-group class="d-flex mb-3 shadow">
        <b-button
            :disabled="$store.state.ta_list  == null || $store.state.ta_list.length === 0 || $store.state.executing_transaction"
            @click="$store.commit('emptyTransactionList')" variant="outline-danger">
          <netvs-icon icon="delete_transaction_list"></netvs-icon>
          <span class="text-nowrap">
                        {{ $t('components.transaction_list.clear_list') }}
                    </span>
        </b-button>
        <b-dropdown
            :disabled="$store.state.ta_list  == null || $store.state.ta_list.length === 0 || $store.state.executing_transaction"
            variant="primary"
            @click="$store.dispatch('testTransaction')" split>
          <template #button-content>
            <netvs-icon icon="test"></netvs-icon>
            {{ $t('components.transaction_list.test') }}
          </template>
          <b-dropdown-item @click="ta_test_submgr_prepare()">
            {{ $t('components.transaction_list.test_with_subaccount') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="check_rights($store.state, 'wapi.su_access')" @click="$bvModal.show('taSu')">
            {{ $t('components.transaction_list.test_as_different_user') }}
          </b-dropdown-item>
        </b-dropdown>
        <b-button
            :disabled="$store.state.ta_list  == null || $store.state.ta_list.length === 0 || $store.state.executing_transaction"
            variant="success" @click="executeTa">
          <netvs-icon icon="execute"></netvs-icon>
          {{ $t('components.transaction_list.apply') }}
        </b-button>
      </b-button-group>
      <b-button-group class="d-flex mb-3 shadow">
        <b-button v-if="!$store.state.impersonate_user && check_rights($store.state, 'wapi.su_access')"
                  variant="outline-secondary" @click="$bvModal.show('modal_impersonate_user'); selected_impersonate_user = ''">
          <netvs-icon icon="impersonate"/>
          {{ $t('components.transaction_list.impersonate') }}
        </b-button>
      </b-button-group>
      <div class="text-center" v-if="!($store.state.ta_list === null || $store.state.ta_list.length === 0)">
        <b-button variant="link" @click="downloadTA">
          <netvs-icon icon="export"></netvs-icon>
          {{ $t('components.transaction_list.export_transaction_list') }}
        </b-button>
      </div>
      <template v-if="$store.state.transaction_result">
        <hr/>
        <b-button-close @click="$store.commit('removeTransactionResult')"/>
        <template v-if="$store.state.transaction_result.type === 'error'">
          <p class="text-center text-danger">
            <netvs-icon icon="transaction_error"></netvs-icon>
            {{ $t('components.transaction_list.further_error_info') }}:
          </p>
          <CopyField class="shadow" variant="danger"
                     :text="JSON.stringify({ta: api_ta, result: $store.state.transaction_result.error}, null, 1)"
                     multiline :height_override="$store.state.developer?'600px':undefined"/>
        </template>
        <template v-if="$store.state.transaction_result.type === 'success'">
          <p class="text-center text-success">
            <netvs-icon icon="transaction_success"></netvs-icon>
            {{ $t('components.transaction_list.transaction_success') }}
          </p>
        </template>
        <template v-if="$store.state.transaction_result.type === 'test-success'">
          <p class="text-center text-primary">
            <netvs-icon icon="test"></netvs-icon>
            {{ $t('components.transaction_list.test_success') }}
          </p>
        </template>
        <template v-if="visible_results && visible_results.length > 0">
          <hr/>
          <p class="text-center">
            <b-link @click="$bvModal.show('ta_results')">
              {{ $t('components.transaction_list.show_transaction_results') }}
            </b-link>
          </p>
        </template>
      </template>
      <template v-if="$store.state.developer">
        <hr/>
        <p class="text-center">
          <b-button v-b-modal.import_json_template_from_file variant="link">
            <netvs-developer-marker/>
            {{ $t('components.transaction_list.import_transaction_template') }}
          </b-button>
        </p>
      </template>
      <DBEditor modal_id="ta-edit"
                :object_fq_name="edit_elem.content.object_fq_name"
                :object_function="edit_elem.content.object_function"
                :old_uuid="edit_elem.uuid"
                :presets="edit_elem.content.parameters.new"
                :old_data="edit_elem.content.parameters.old"
                :non_optionals="edit_elem.content.non_optionals"
                :non_optionals_order="edit_elem.content.non_optionals_order"
                :input_reducer="edit_elem.content.input_reducer"
                :preview_fields="edit_elem.content.preview_fields"
                :object_title="edit_elem.content.object_title"
                :show_result="edit_elem.content.show_result"
                @ready="modalReady()"
      ></DBEditor>
    </div>
    <div class="mt-auto">
      <hr class="my-0">
      <button :disabled="!$store.state.show_sidebar_right" class="collapse-button"
              @click.stop="$store.commit('showSidebarRight', !$store.state.show_sidebar_right)">
        {{ Math.random() > .99 ? $tc('system.collapse', 2) : $tc('system.collapse', 1) }}
        <span class="sidebar-icon"><netvs-icon icon="sidebar_collapse_to_right"></netvs-icon></span>
      </button>
    </div>
    <b-modal id="import_json_template_from_file" @ok="importJSONTemplate" ok-title="Import"
             :title="$t('components.transaction_list.import_transaction_template')" :cancel-title="$t('system.cancel')"
             @show="json_template_import_valid = null" size="lg">
      <b-alert show variant="warning">
        <netvs-icon icon="external_hazard"></netvs-icon>
        <b>{{ $t('system.attention') }}!</b> {{ $t('components.transaction_list.json_template_warning') }}
      </b-alert>
      <b-form-radio-group v-model="json_template_import_method">
        <b-form-radio value="use_file">{{ $t('components.transaction_list.use_file') }}</b-form-radio>
        <b-form-file v-if="json_template_import_method === 'use_file'" :state="json_template_import_valid"
                     accept="application/json" v-model="json_template_import_file"></b-form-file>
        <hr/>
        <b-form-radio value="use_text">{{ $t('components.transaction_list.use_text') }}</b-form-radio>
        <b-form-textarea class="text-monospace" rows="20" v-if="json_template_import_method === 'use_text'"
                         :state="json_template_import_valid" v-model="json_template_import_direct"></b-form-textarea>
      </b-form-radio-group>
      <b-form-invalid-feedback :state="json_template_import_valid">
        {{ $t('components.transaction_list.invalid_json_template') }}:
        <ul v-if="Array.isArray(json_template_import_error)">
          <li v-for="err in json_template_import_error" :key="err.message"><span v-if="'instancePath' in err"><code>{{err.instancePath}}</code>: </span>{{ err.message }}</li>
        </ul>
        <code v-else>{{ json_template_import_error }}</code>
      </b-form-invalid-feedback>
    </b-modal>
    <b-modal id="import_ta_from_file" @ok="importTa" :ok-disabled="!import_file" ok-title="Import"
             :title="$t('components.transaction_list.import_transaction_list')" :cancel-title="$t('system.cancel')"
             @show="import_valid = null">
      <b-alert show variant="warning">
        <netvs-icon icon="external_hazard"></netvs-icon>
        <b>{{ $t('system.attention') }}!</b> {{ $t('components.transaction_list.trusted_source_warning') }}
      </b-alert>
      <b-form-file :state="import_valid" accept="application/json" v-model="import_file"></b-form-file>
      <b-form-invalid-feedback :state="import_valid">
        {{ $t('components.transaction_list.invalid_file') }}
      </b-form-invalid-feedback>
    </b-modal>
    <b-modal ok-only id="ta_results" :title="$t('components.transaction_list.transaction_result')" size="lg"
             v-if="visible_results.length > 0">
      <template v-if="visible_results[visible_ta_res_index].item.length === 0">
        <p class="text-center"><i>{{ $t('components.transaction_list.empty_result') }}</i></p>
      </template>
      <template v-else-if="current_result_ta_object_type == 'wapi_auth'">
        <template v-if="visible_results[visible_ta_res_index].item[0]">
          <CopyField class="shadow" :text="visible_results[visible_ta_res_index].item[0].token"/>
          <b-alert show variant="warning" class="mb-0 mt-3">
            {{ $t('components.transaction_list.token_volatility_warning') }}
          </b-alert>
          <hr/>
          <i18n path="components.transaction_list.config_snippet_description.description" tag="p"
                for="components.transaction_list.config_snippet_description">
            <template #python_library_link>
              <b-link href="https://gitlab.kit.edu/scc-net/netvs/netdb-client" target="_blank">
                {{ $t('components.transaction_list.config_snippet_description.python_library') }}
              </b-link>
            </template>
            <template #acme4netvs_link>
              <b-link href="https://gitlab.kit.edu/KIT/KIT-CA/acme4netvs" target="_blank">
                {{ $t('components.transaction_list.config_snippet_description.acme4netvs') }}
              </b-link>
            </template>
            <template #code>
              <code>0600</code>
            </template>
            <template #path>
              <code>{{ $t('components.transaction_list.config_snippet_description.path') }}</code>
            </template>
          </i18n>
          <CopyField class="shadow mb-3" multiline code variant="secondary" :text="config_snippet_text"/>
        </template>
        <template v-else>
          <p class="text-center"><i>{{ $t('components.transaction_list.token_not_viewable') }}</i></p>
        </template>
      </template>
      <pre v-else>
      {{ visible_results[visible_ta_res_index].item }}
      </pre>
      <b-button-group class="d-flex" align="center">
        <b-button @click="visible_ta_res_index--" :disabled="visible_ta_res_index === 0">
          {{ $t('system.previous') }}
        </b-button>
        <b-button @click="visible_ta_res_index++" :disabled="visible_ta_res_index === (visible_results.length-1)">
          {{ $t('system.next') }}
        </b-button>
      </b-button-group>
    </b-modal>
    <b-modal id="modal_impersonate_user" :title="$t('components.transaction_list.impersonate')"
             :ok-title="$t('components.transaction_list.impersonate')" :cancel-title="$t('system.cancel')"
             @ok="impersonate()">
      <form @submit.stop.prevent="impersonate()">
        <b-button type="submit" style="z-index: -1; position:absolute; opacity: 0" tabindex="-1">.</b-button>
        <Typeahead :data="impersonate_user_suggestions"
                   @select="e => {this.selected_impersonate_user = this.impersonate_user = e ? e.login_name : ''}"
                   v-model="impersonate_user"
                   placeholder="Login-Name"
                   :loading="searching_mgrs"
                   :serializer="serializeMgr"
                   :min-matching-chars="2"
                   :debounce="250"
                   :form_eval_mode="false"
                   :autofocus="true"/>
      </form>
    </b-modal>
    <b-modal id="taSu" :title="$t('components.transaction_list.test_as_different_user')"
             :ok-title="$t('components.transaction_list.test')" :cancel-title="$t('system.cancel')"
             @ok="ta_test()">
      <form @submit.stop.prevent="ta_test()">
        <b-button type="submit" style="z-index: -1; position:absolute; opacity: 0" tabindex="-1">.
        </b-button>
        <b-input v-model="su_user" placeholder="Login-Name"></b-input>
      </form>
    </b-modal>
    <b-modal id="taSuSub" :title="$t('components.transaction_list.test_with_subaccount')"
             :ok-disabled="su_user === null" :ok-title="$t('components.transaction_list.test')"
             :cancel-title="$t('system.cancel')"
             @ok="ta_test()">
      <form @submit.stop.prevent="ta_test()">
        <b-button type="submit" style="z-index: -1; position:absolute; opacity: 0" tabindex="-1">.
        </b-button>
        <b-select v-model="su_user">
          <b-select-option :value="null" disabled>{{ $t('system.please_select') }}</b-select-option>
          <b-select-option v-for="s in subaccounts" :key="s.login_name" :value="s.login_name">{{ s.login_name }}
            <template v-if="s.description"> - {{ s.description }}</template>
          </b-select-option>
        </b-select>
      </form>
    </b-modal>
    <JSONTemplateTransactionGroupEditor :template="json_template" :bound_variables="json_template_bound_vars"
                                        modal_id="main_json_template_editor" :old_uuid="json_template_old_uuid"/>
    <BundledDBEditor
        v-if="bundle_edit_elem && 'ta_type' in bundle_edit_elem && bundle_edit_elem.ta_type.startsWith('db_editor_bundle_')"
        modal_id="main_db_editor_bundle_editor"
        :object_fq_name="bundle_edit_elem.content.object_fq_name"
        :object_function="bundle_edit_elem.content.object_function"
        :old_uuid="bundle_edit_elem.uuid"
        :presets="bundle_edit_elem.content.parameters.new"
        :old_data="bundle_edit_elem.content.parameters.old"
        :non_optionals="bundle_edit_elem.content.non_optionals"
        :non_optionals_order="bundle_edit_elem.content.non_optionals_order"
        :input_reducer="bundle_edit_elem.content.input_reducer"
        :preview_fields="bundle_edit_elem.content.preview_fields"
        :object_title="bundle_edit_elem.content.object_title"
        :show_result="bundle_edit_elem.content.show_result"
        :template="bundle_edit_elem.content.template"
        :prepend="bundle_edit_elem.ta_type.endsWith('prepend')"
        @ready="bundleModalReady()"
    ></BundledDBEditor>
  </b-overlay>
</template>

<script>
import Draggable from 'vuedraggable'
import DBEditor from './db-editor/APIObjectDBEditor.vue'
import TransactionUtil from '@/util/transactionutil'
import Vue from 'vue'
import CopyField from './CopyField'
import APIUtils from '@/util/apiutil'
import AccountService from '@/api-services.gen/cntl.mgr'
import JSONTemplateTransactionGroupEditor from '@/components/db-editor/JSONTemplateTransactionGroupEditor.vue'
import NetvsDeveloperMarker from '@/components/NETVSDeveloperMarker.vue'
import BundledDBEditor from '@/components/db-editor/BundledDBEditor.vue'
import TACardErrorDisplay from '@/components/TACardErrorDisplay.vue'
import Typeahead from '@/components/Typeahead'
import SearchService from '@/api-services/search.service'
import JsonTemplateUtil from '@/util/jsontemplateutil'

export default {
  name: 'TransactionList',
  components: {
    TACardErrorDisplay,
    BundledDBEditor,
    NetvsDeveloperMarker,
    JSONTemplateTransactionGroupEditor,
    CopyField,
    Draggable,
    DBEditor,
    Typeahead
  },
  computed: {
    current_result_ta_entry() {
      if (this.$store.state.transaction_result && 'ta_list' in this.$store.state.transaction_result && this.visible_results.length > 0) {
        return this.$store.state.transaction_result.ta_list[this.visible_results[this.visible_ta_res_index].index]
      }
      return null
    },
    current_result_ta_object_type() {
      const res = this.current_result_ta_entry
      if (res.ta_type === 'db_editor_simple' || res.ta_type.startsWith('db_editor_bundle_')) {
        return res.content.object_functions[res.content.object_function].object_type
      } else if (res.ta_type === 'json_template') {
        const elem_by_idx = APIUtils.dict_by_value_of_array(res.content.template.transaction, 'idx')
        window.console.debug(elem_by_idx)
        const n_p = elem_by_idx[res.content.template.returning[0]].name.split('.')
        window.console.debug(n_p)
        return n_p[1]
      }
      return null
    },
    visible_results() {
      const res = []
      if (this.$store.state.transaction_result && 'ta_list' in this.$store.state.transaction_result) {
        for (const [index, item] of this.$store.state.transaction_result.ta_list.entries()) {
          if (item.ta_type === 'db_editor_simple' || item.ta_type.startsWith('db_editor_bundle_')) {
            if (item.content.show_result) {
              res.push({index: index, item: this.$store.state.transaction_result.result[item.uuid]})
            }
          } else if (item.ta_type === 'json_template') {
            for (const i of item.content.template.returning) {
              res.push({index: index, item: this.$store.state.transaction_result.result[item.uuid + '_' + i]})
            }
          }
        }
      }
      return res
    },
    api_ta() {
      return APIUtils.buildAPITaFromTaObjectArray(this.$store.state, true)
    },
    config_snippet_text() {
      return `[DEFAULT]
endpoint = ${this.$store.state.sysinfo.host_oper_mode.mode}

[${this.$store.state.sysinfo.host_oper_mode.mode}]
base_url = ${this.$store.state.sysinfo.host_oper_mode.is_prod ? 'api.netdb.scc.kit.edu' : `api.netdb-${this.$store.state.sysinfo.host_oper_mode.mode}.scc.kit.edu`}
token = ${this.visible_results[this.visible_ta_res_index].item[0].token}`
    }
  },
  methods: {
    compile_template_string: JsonTemplateUtil.compile_string,
    check_rights: APIUtils.checkPermission,
    function2variant: TransactionUtil.function2variant,
    function2text: TransactionUtil.function2text,
    functionWithObject2text: TransactionUtil.functionWithObject2text,
    editElement(element) {
      if (element.ta_type === 'db_editor_simple') {
        this.should_edit = this.edit_elem !== element
        this.edit_elem = element
        if (!this.should_edit) {
          this.$bvModal.show('ta-edit')
        }
      } else if (element.ta_type === 'json_template') {
        this.json_template = element.content.template
        this.json_template_bound_vars = element.content.variables
        this.json_template_old_uuid = element.uuid
        this.$bvModal.show('main_json_template_editor')
      } else if (element.ta_type.startsWith('db_editor_bundle_')) {
        window.console.debug('edit bundle')
        this.bundle_should_edit = this.bundle_edit_elem !== element
        this.bundle_edit_elem = element
        if (!this.should_edit) {
          this.$bvModal.show('main_db_editor_bundle_editor')
        }
      }
    },
    downloadTA() {
      const creation_time = Date.now()
      const blob = new Blob([JSON.stringify({
        creation_time: creation_time,
        transaction: this.$store.state.ta_list,
        serialization_version: 2,
        author: this.$store.state.user.login_name
      }, null, 2)], {type: 'application/json'})
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = 'netvs-transaction-' + this.$store.state.user.login_name + '-' + creation_time + '.json'
      link.click()
      URL.revokeObjectURL(link.href)
    },
    async importJSONTemplate(e) {
      e.preventDefault()
      this.json_template_import_valid = true
      let data = null
      if (this.json_template_import_method === 'use_file') {
        data = await this.json_template_import_file.text()
      } else if (this.json_template_import_method === 'use_text') {
        data = this.json_template_import_direct
      }
      try {
        const obj = JSON.parse(data)
        this.json_template = obj
      } catch (e) {
        this.json_template_import_valid = false
        this.json_template_import_error = e.toString()
      }
      if (this.json_template) {
        const validation_res = await JsonTemplateUtil.validate_template(this.json_template)
        if (!validation_res.status) {
          this.json_template = null
          this.json_template_import_valid = false
          this.json_template_import_error = validation_res.errors
        }
      }
      if (this.json_template_import_valid) {
        this.$bvModal.show('main_json_template_editor')
      }
    },
    importTa(e) {
      e.preventDefault()
      this.import_file.text().then((data) => {
        try {
          const obj = JSON.parse(data)
          if (obj.serialization_version !== 2 || !('transaction' in obj)) {
            this.import_valid = false
            return
          }
          // FIXME: we (may) need more checks
          this.$store.commit('replaceTransactionList', obj.transaction)
          this.import_file = this.import_valid = null
          this.$bvModal.hide('ta-import')
        } catch (e) {
          window.console.debug('import failed:', e)
          this.import_valid = false
        }
      }).catch(() => {
        this.import_valid = false
      })
    },
    modalReady() {
      window.console.debug('modalReady')
      if (this.should_edit && this.edit_elem != null) {
        this.should_edit = false
        const self = this
        Vue.nextTick(function () {
          self.$bvModal.show('ta-edit')
        })
      }
    },
    bundleModalReady() {
      if (this.bundle_should_edit && this.bundle_edit_elem != null) {
        this.bundle_should_edit = false
        const self = this
        Vue.nextTick(function () {
          self.$bvModal.show('main_db_editor_bundle_editor')
        })
      }
    },
    move(from, to) {
      this.$store.commit('moveTaElements', {from: from, to: to})
    },
    drag_change(val) {
      this.move(val.moved.oldIndex, val.moved.newIndex)
    },
    async ta_test_submgr_prepare() {
      this.$store.commit('setTransactionBusy', true)
      this.subaccounts = (await AccountService.list(this.$store.state, {
        is_own: true,
        is_sub: true
      })).data[0]
      this.$store.commit('setTransactionBusy', false)
      this.$bvModal.show('taSuSub')
    },
    impersonate() {
      if (this.impersonate_user_suggestions.length === 0) {
        return
      }
      this.getMgrs()
      if (this.impersonate_user_suggestions.length === 1) {
        this.selected_impersonate_user = this.impersonate_user_suggestions[0].login_name
      }
      if (!this.selected_impersonate_user || this.selected_impersonate_user.length === 0) {
        this.$bvToast.toast(this.$t('system.invalid_impersonate_user_event'), {
          title: this.$t('system.invalid_impersonate_user_event_title'),
          variant: 'warning',
          solid: true,
          autoHideDelay: 10000
        })
        return
      }
      this.$store.commit('updateImpersonatingUser', this.selected_impersonate_user)
      this.$bvModal.hide('modal_impersonate_user')
    },
    ta_test() {
      this.$store.commit('setTaSuUser', this.su_user)
      this.$store.dispatch('testTransaction')
      this.$bvModal.hide('taSu')
    },
    async executeTa() {
      this.visible_ta_res_index = 0
      await this.$store.dispatch('executeTransaction')
      if (this.$store.state.transaction_result.type !== 'error') {
        if (this.$store.state.refreshHandleObjType == null) {
          this.$store.commit('reloadRouterComp')
        } else {
          if (this.$store.state.refreshHandleVariant) {
            const variant = this.$store.state.refreshHandleVariant
            await this.$router.push('/launch/' + this.$store.state.refreshHandleObjType + '/' + this.$store.state.refreshHandleGPK + '#' + variant)
          } else {
            await this.$router.push('/launch/' + this.$store.state.refreshHandleObjType + '/' + this.$store.state.refreshHandleGPK)
          }
        }
        if (this.visible_results.length > 0) {
          this.$bvModal.show('ta_results')
        }
      }
    },
    async getMgrs(q) {
      this.searching_mgrs = true
      const res = await SearchService.searchMgr(this.$store.state, this.impersonate_user)
      this.impersonate_user_suggestions = res.data.mgr_list
      this.searching_mgrs = false
    },
    serializeMgr(mgr) {
      if (mgr.login_name === mgr.first_name || mgr.first_name === null || mgr.last_name === null) {
        if (mgr.is_svc) {
          return `${mgr.login_name} (SVC-ID: ${mgr.svc_id})`
        }
        return mgr.login_name
      }
      return mgr.login_name + ' (' + mgr.first_name + ' ' + mgr.last_name + ')'
    },
  },
  created() {
  },
  watch: {
    impersonate_user(new_q) {
      this.getMgrs(new_q)
    }
  },
  data() {
    return {
      visible_ta_res_index: 0,
      import_file: null,
      import_valid: null,
      json_template_import_valid: null,
      json_template_import_error: '',
      json_template_import_method: 'use_file',
      json_template_import_direct: null,
      json_template_import_file: null,
      json_template_old_uuid: null,
      json_template: null,
      impersonate_user: this.$store.state.impersonate_user || '',
      selected_impersonate_user: this.$store.state.impersonate_user || '',
      impersonate_user_suggestions: [],
      searching_mgrs: false,
      json_template_bound_vars: {},
      edit_elem: {
        content: {
          object_fq_name: null,
          object_function: 'dummy',
          parameters: {new: {}, old: {}}
        },
        uuid: 'dummy',
      },
      should_edit: false,
      bundle_edit_elem: {
        content: {
          object_fq_name: null,
          object_function: 'dummy',
          parameters: {new: {}, old: {}}
        },
        uuid: 'dummy',
      },
      bundle_should_edit: false,
      su_user: null,
      subaccounts: null
    }
  }
}
</script>

<style lang="scss" scoped>

@import '../assets/css/variables.scss';

.element-number {
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 0;
  font-size: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 30px;
}

.handle {
  cursor: grab;
}

.move-icons {
  position: absolute;
  bottom: 10px;
}

.move-icon {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  font-size: 24px;
  width: 30px;
  user-select: none;
  z-index: 69;
  cursor: auto;
}

.element-content {
  margin-left: 30px;
  min-height: 80px;
}

.element-buttons {
  position: relative;
  right: 0;
  top: 0;
}

.error-warning {
  position: relative;
  bottom: 0;
  color: red;
}

.collapse-button {
  width: 100%;
  border: none;
  color: $sidenavbar-button-color;
  height: $left-sidebar-hidden-width;
  background: none;
  padding: 0;
}

.collapse-button:hover {
  background-color: $sidenavbar-active-hover-color;
}
</style>
