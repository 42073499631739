<template>
  <div class="db_editor_base">
    <template v-if="!non_optionals_order">
      <template v-for="(data,attr) in object_functions[object_function].parameters">
        <APIObjectDataEditFieldList :object_attribute="data" :attribute_preset="computedPresets[attr]"
                                    :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                                    :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                                    :attribute_name="attr"
                                    :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                                    :input_reducer="input_reducer[attr]"
                                    v-bind:key="attr"
                                    v-if="'new' in data && (data.new.isRequired || non_optionals.includes(attr))"></APIObjectDataEditFieldList>
        <DataEditField :object_attribute="data" :attribute_preset="computedPresets[attr]"
                       :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                       :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                       :attribute_name="attr"
                       :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                       :input_reducer="input_reducer[attr]"
                       v-bind:key="attr"
                       v-if="'new' in data && (data.new.isRequired || non_optionals.includes(attr))"></DataEditField>
      </template>
      <template>
        <b-link v-b-toggle.collapse-optional>
          <span>{{ $tc('components.db_editor.advanced_parameter', 2) }}</span>
          <NETVSIcon class="collapse-icon" icon="collapse"></NETVSIcon>
        </b-link>
        <b-collapse id="collapse-optional">
          <hr/>
          <template v-for="(data,attr) in object_functions[object_function].parameters">
            <DataEditField :object_attribute="data" :attribute_preset="computedPresets[attr]"
                           :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                           :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                           :attribute_name="attr"
                           :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                           :input_reducer="input_reducer[attr]"
                           v-bind:key="attr"
                           v-if="'new' in data && (!data.new.isRequired && !non_optionals.includes(attr))"></DataEditField>
          </template>
          <hr/>
          <b-checkbox v-model="show_result_int">{{ $t('components.db_editor.request_result') }}</b-checkbox>
        </b-collapse>
      </template>
    </template>
    <template v-else>
      <template v-for="attr in non_optionals_order">
        <APIObjectDataEditFieldList :object_attribute="object_functions[object_function].parameters[attr]"
                                    :attribute_preset="computedPresets[attr]"
                                    :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                                    :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                                    :attribute_name="attr"
                                    :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                                    :input_reducer="input_reducer[attr]"
                                    v-bind:key="attr"
                                    v-if="object_functions[object_function].parameters[attr] && object_functions[object_function].parameters[attr].data_type.endsWith('_array')"
        />
        <DataEditField :object_attribute="object_functions[object_function].parameters[attr]"
                       :attribute_preset="computedPresets[attr]"
                       :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                       :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                       :attribute_name="attr"
                       :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                       :input_reducer="input_reducer[attr]"
                       v-else
                       v-bind:key="attr"
        />
      </template>
      <template>
        <b-link v-b-toggle.collapse-optional>
          <span>{{ $tc('components.db_editor.advanced_parameter', 2) }}</span>
          <NETVSIcon class="collapse-icon" icon="collapse"></NETVSIcon>
        </b-link>
        <b-collapse id="collapse-optional">
          <hr v-if="object_functions[object_function].parameters.length > 0" />
          <template v-for="(data,attr) in object_functions[object_function].parameters">
            <template v-if="'new' in data && !non_optionals_order.includes(attr)">
              <APIObjectDataEditFieldList
                  :object_attribute="data" :attribute_preset="computedPresets[attr]"
                  :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                  :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                  :attribute_name="attr"
                  :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                  :input_reducer="input_reducer[attr]"
                  v-bind:key="attr"
                  v-if="object_functions[object_function].parameters[attr] && object_functions[object_function].parameters[attr].data_type === 'fqdn_array'"
              />
              <DataEditField
                  :object_attribute="data" :attribute_preset="computedPresets[attr]"
                  :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                  :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                  :attribute_name="attr"
                  :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                  :input_reducer="input_reducer[attr]"
                  v-bind:key="attr"
                  v-else
              />
            </template>
          </template>
          <hr/>
          <b-checkbox @change="$emit('show_result_change', show_result_int)" v-model="show_result_int">
            {{ $tc('components.db_editor.show_returned_element_on_success', 1) }}
          </b-checkbox>
        </b-collapse>
      </template>
    </template>
  </div>
</template>

<script>
import DataEditField from './APIObjectDataEditField.vue'
import TransactionUtil from '@/util/transactionutil'
import APIObjectDataEditFieldList from '@/components/db-editor/APIObjectDataEditFieldList.vue'
import NETVSIcon from '@/icons/NETVSIcon.vue'

export default {
  name: 'APIObjectDBEditorBase',
  components: { NETVSIcon, APIObjectDataEditFieldList, DataEditField},
  data() {
    return {
      show_result_int: false,
    }
  },
  methods: {
    function2variant: TransactionUtil.function2variant,
    function2text: TransactionUtil.function2text,
    functionWithObject2text: TransactionUtil.functionWithObject2text,
  },
  props: {
    object_definition: {
      required: true
    },
    object_definition_referencing_by_attribute: {
      required: true
    },
    object_definition_reference_names_by_attribute: {
      required: true
    },
    object_functions: {
      required: true
    },
    object_fq_name: {
      required: true
    },
    object_function: {
      type: String,
      required: true
    },
    presets: {
      type: Object,
      default() {
        return {}
      }
    },
    input_reducer: {
      type: Object,
      default() {
        return {}
      }
    },
    old_data: {
      required: false,
      default() {
        return {}
      },
      type: Object
    },
    non_optionals: {
      required: false,
      default() {
        return []
      },
      type: Array
    },
    non_optionals_order: {
      required: false,
      type: Array
    },
    nullable_non_expert: {
      required: false,
      default() {
        return []
      },
      type: Array
    },
    old_uuid: {
      required: false,
      type: String,
      default() {
        return null
      }
    },
    preview_fields: {
      required: false,
      type: Array,
      default() {
        return null
      }
    },
    object_title: {
      required: false,
      default() {
        return null
      }
    },
    show_result: {
      default() {
        return false
      }
    },
  },
  computed: {
    computedPresets() {
      if (this.object_function === 'update') {
        return this.presets
      }
      if (this.presets) {
        const pres = {}
        Object.assign(pres, this.presets)
        for (const k in this.object_functions[this.object_function].parameters) {
          if ('new' in this.object_functions[this.object_function].parameters[k]) {
            if ('data_default' in this.object_functions[this.object_function].parameters[k].new && !(k in pres)) {
              pres[k] = this.object_functions[this.object_function].parameters[k].new.data_default
            }
          }
        }
        return pres
      }
      return {}
    }
  },
  watch: {
    $props: {
      immediate: true,
      deep: true,
      async handler() {
        this.show_result_int = this.show_result
      }
    }
  }
}
</script>

<style scoped>

</style>
