function getTitle (vm) {
  let prefix = ''
  if (vm.$route && vm.$route.meta.resolveName) {
    prefix = `[${vm.$store.state.sysinfo.host_oper_mode.mode}] `
    if (vm.$store.state.sysinfo.host_oper_mode.is_prod) {
      prefix = ''
    }
    return prefix + 'SCC - NETVS | ' + vm.$route.meta.resolveName(vm.$route.params)
  }
  return prefix + 'SCC - NETVS'
}

export default {
  created () {
    const title = getTitle(this)
    if (title) {
      document.title = title
    }
  }
}
