<template>
  <div>
    <div v-for="(a,index) in $store.state.sys_alerts.concat($store.state.maint_alerts)" :key="a.title[$store.state.locale] || a.title['de']">
      <h4>{{ a.title[$store.state.locale] || a.title['de'] }}</h4>
      <p>{{ a.content[$store.state.locale] || a.title['de'] }}</p>
      <hr v-if="index != $store.state.sys_alerts.length + $store.state.maint_alerts.length - 1"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemAlertDisplay'
}
</script>

<style scoped>

</style>
