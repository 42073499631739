<template>
  <netvs-icon v-if="$store.state.developer" :size="size" icon="development_feature"></netvs-icon>
</template>

<script>

export default {
  name: 'netvs-developer-marker',
  props: {
    size: {
      type: String,
      default() {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
