<template>
  <div v-if="variable">
    <b-form-group
        :state="(!variable.optional&&(!data)&&variable.type!=='bool')?false:null"
        :description="show_description?variable.description[$store.state.locale]:undefined">
      <template v-slot:invalid-feedback>
        {{ $t('components.data_edit_field.feedback_content_required') }}
      </template>
      <template v-slot:label v-if="show_label">
        <netvs-icon v-if="icon" :icon="icon"/>
        {{ variable.friendly_name[$store.state.locale] }}
      </template>
      <b-form-input :value="value || variable.default"
                    v-if="variable.type === 'int'"
                    type="number"
                    :name="variable_id"
                    :disabled="make_null"
                    :state="(!variable.optional&&(!data))?false:null"
      ></b-form-input>
      <template
          v-else-if="variable.type === 'datetime' || variable.type === 'date'">
        <b-row>
          <b-col>
            <b-form-datepicker :label-reset-button="$t('components.data_edit_field.remove_date')" :reset-value="null"
                               reset-button
                               :state="(!variable.optional&&!data)?false:null"
                               @input="composeDate()" v-model="date_picker"
                               locale="de"></b-form-datepicker>
          </b-col>
          <b-col v-if="variable.type === 'datetime'">
            <b-form-timepicker reset-value="00:00" reset-button @input="composeDate()" v-model="time_picker"
                               locale="de"
                               :state="(!variable.optional&&!data)?false:null"
            />
          </b-col>
        </b-row>
        <input type="hidden" :value="composedDate" :name="variable_id"/>
      </template>
      <b-input-group v-else-if="variable.type==='bool'">
        <input type="hidden" :name="variable_id" :value="false"/>
        <b-checkbox :checked="value || variable.default === 'true' || false" :name="variable_id"
                    :disabled="make_null" :state="null"
        />
      </b-input-group>
      <b-form-select
          :state="(!variable.optional&&!data)?false:null"
          :name="variable_id" v-else-if="variable.type === 'select'" :value="variable.default">
        <template v-slot:first>
          <b-form-select-option :value="null" disabled>-- {{ $t('components.data_edit_field.make_a_choice') }} --
          </b-form-select-option>
        </template>
        <b-form-select-option v-for="(data,key) in variable.type_params.entries" :key="key" :value="key">{{
            data.display_name
          }}
        </b-form-select-option>
      </b-form-select>
      <div v-else-if="variable.type === 'typeahead'">
        <typeahead :data="query_data" v-model="query_search"
                   @select="handleSelect($event)"
                   @input="handleDeselect()"
                   :serializer="serialize"
                   :placeholder="(this.query_data === null ? $t('system.loading') : '')"
                   :min-matching-chars="-1"
                   :max-matches="-1"
                   :disabled="this.query_data === null"
                   compact
                   :state="(!variable.optional&&!data)?false:null"
                   class="mb-3" id="typeahead-select"/>
        <b-form-input hidden v-model="data"
                      :name="variable_id"
                      :value="value || variable.default"
        ></b-form-input>
      </div>
      <template v-else-if="variable.type === 'fqdn'">
        <b-form-input v-model="data"
                      :value="value || variable.default"
                      @input="normalizeFQDN()"
                      :state="(!variable.optional&&!data)?false:null"
        ></b-form-input>
        <input type="hidden" :value="normalized_FQDN" :name="variable_id"/>
      </template>
      <template v-else-if="variable.type === 'ip_addr'">
        <b-form-input v-model="data"
                      :value="value || variable.default"
                      @input="normalizeIPAddress()"
                      :state="(!variable.optional&&!data)?false:null"
        ></b-form-input>
        <input type="hidden" :value="normalized_ip_address" :name="variable_id"/>
      </template>
      <template v-else-if="variable.type === 'mac_addr'">
        <b-form-input v-model="data"
                      :value="value || variable.default"
                      @input="normalizeMACAddress()"
                      :state="(!variable.optional&&!data)?false:null"
        ></b-form-input>
        <input type="hidden" :value="normalized_mac_address" :name="variable_id"/>
      </template>
      <b-form-input v-else v-model="data"
                    :name="variable_id"
                    :disabled="make_null"
                    :value="value || variable.default"
                    :state="(!variable.optional&&!data)?false:null"
      ></b-form-input>
      <b-checkbox :name="variable_id+'_null'" v-model="make_null"
                  v-if="$store.state.expert && variable.nullable">{{ $t('system.null') }}
        <NetvsExpertMarker/>
      </b-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import Typeahead from '@/components/Typeahead.vue'
import TransactionService from '@/api-services/transaction.service'
import fqdnutil from '@/util/fqdnutil'
import ipaddress from '@/util/ipaddress'

export default {
  name: 'JSONTemplateDataEditField',
  components: {Typeahead},
  data() {
    return {
      query_data: null,
      query_search: '',
      serialize: item => item[this.variable.type_params.display_value],
      data: this.value,
      date_picker: null,
      time_picker: '00:00',
      composedDate: '__NULL',
      normalized_FQDN: '',
      normalized_ip_address: '',
      normalized_mac_address: '',
      make_null: false,
    }
  },
  methods: {
    handleSelect(val) {
      if (val !== null) {
        this.data = val[this.variable.type_params.return_value]
      }
    },
    handleDeselect() {
      this.data = null
    },
    composeDate() {
      if (this.date_picker === null || this.date_picker === '') {
        this.composedDate = '__NULL'
        return
      }
      if (this.time_picker === null || this.time_picker === '' || this.time_picker.toLowerCase().includes('nan')) {
        this.composedDate = new Date(this.date_picker + 'T00:00:00').toJSON()
        return
      }
      this.composedDate = new Date(this.date_picker + 'T' + this.time_picker).toJSON()
    },
    normalizeFQDN() {
      this.normalized_FQDN = fqdnutil.normalize_fdqn(this.data)
    },
    normalizeIPAddress() {
      if (this.variable.type_params.address_type === 'v4' || this.variable.type_params.address_type === 'v4+v6') {
        if (ipaddress.is_ip_v4(this.data.trim())) {
          this.normalized_ip_address = this.data.trim()
        } else {
          this.normalized_ip_address = ''
        }
      } else if (this.variable.type_params.address_type === 'v6' || this.variable.type_params.address_type === 'v4+v6') {
        if (ipaddress.is_ip_v6(this.data.trim())) {
          this.normalized_ip_address = ipaddress.abbreviate_ipv6(this.data.trim())
        } else {
          this.normalized_ip_address = ''
        }
      }
    },
    normalizeMACAddress() {
      if (ipaddress.is_mac_address(this.data.trim())) {
        this.normalized_mac_address = this.data.trim()
      } else {
        this.normalized_mac_address = ''
      }
    },
    async refresh() {
      if (this.variable.type === 'typeahead') {
        let res = null
        res = await TransactionService.execute(this.$store.state, this.variable.type_params.query, false)
        this.query_data = res.data[this.variable.type_params.query_path]

        if (this.value !== null) {
          // Try finding object in the data
          for (const item of this.query_data) {
            if (item[this.variable.type_params.return_value] === this.value) {
              this.query_search = this.serialize(item)
              break
            }
          }
        }
      } else if (this.variable.type === 'fqdn') {
        this.normalizeFQDN()
      } else if (this.variable.type === 'ip_addr') {
        this.normalizeIPAddress()
      } else if (this.variable.type === 'mac_addr') {
        this.normalizeMACAddress()
      }
    }
  },
  async mounted() {
    await this.refresh()
  },
  props: {
    icon: {
      required: false,
      type: String,
      default() {
        return null
      }
    },
    variable: {
      type: Object,
      required: true
    },
    variable_id: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    show_label: {
      type: Boolean,
      default: true
    },
    show_description: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
