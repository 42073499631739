<template>
  <div id="app">
    <template v-if="!$store.state.init_fail">
      <GlobalSearch/>
      <Navbar/>
      <div id="wrapper"
           :class="{'squished-right': $store.state.show_sidebar_right, 'squished-left': $store.state.show_sidebar_left}">
        <b-card class="sidebar-wrapper left bv-d-md-down-none" no-body
                :class="$store.state.show_sidebar_left ? 'show' : null">
          <SideNavbar/>
        </b-card>
        <div id="page-content-wrapper">
          <div class="container">
            <b-alert v-if="$store.state.locale != 'de'" variant="info" show>
              <b-row>
                <b-col cols="auto" class="my-auto">
                  <netvs-icon icon="language" size="3x"></netvs-icon>
                </b-col>
                <b-col class="h-100 my-auto">
                  <p class="my-auto">{{ $t('system.missing_api_translation') }}</p>
                </b-col>
              </b-row>
            </b-alert>
            <b-alert v-if="$store.state.sysinfo.host_oper_mode.is_devel" show variant="warning">
              <b-row>
                <b-col cols="auto" class="my-auto">
                  <netvs-icon icon="devel_warning" size="3x"></netvs-icon>
                </b-col>
                <b-col class="h-100 my-auto">
                      <span class="my-auto"><b> {{ $t('views.app.devel_instance') }}</b>
                                  <i18n path="system.host_operation_mode.devel.message"
                                        tag="p" for="system.host_operation_mode.devel">
                                    <template #active_development_link>
                                      <a href="https://gitlab.kit.edu/scc-net/netvs/netvs-core/-/tree/devel"
                                         target="_blank">
                                        {{ $t('system.host_operation_mode.devel.active_development') }}</a>
                                    </template>
                                  </i18n>
                      </span>
                </b-col>
              </b-row>
            </b-alert>
            <b-alert v-if="$store.state.sysinfo.host_oper_mode.is_test" show variant="info">
              <b-row>
                <b-col cols="auto" class="my-auto">
                  <netvs-icon icon="test" size="3x"></netvs-icon>
                </b-col>
                <b-col class="my-auto">
                  <span><b class="mr-1"> {{ $t('views.app.test_instance') }}</b>{{
                      $t('system.host_operation_mode.test.message')
                    }}</span>
                </b-col>
              </b-row>
            </b-alert>
            <b-breadcrumb>
              <b-breadcrumb-item v-for="(crumb, index) in breadcrumbs" :key="index"
                                 :to="crumb.to" :active="crumb.disabled || index == breadcrumbs.length -1">
                {{ crumb.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
            <router-view v-bind:key="$store.state.reload_count" v-if="$store.state.spec_ready || $route.path !== '/'"/>
            <SpecReady v-else-if="ready && !$store.state.spec_ready"></SpecReady>
            <NetvsFooter/>
          </div>
        </div>
        <b-card class="sidebar-wrapper right" no-body :class="$store.state.show_sidebar_right ? 'show' : null">
          <Sidebar/>
        </b-card>
      </div>
      <b-modal content-class="text-center" centered id="netvs_user_locked" hide-footer hide-header size="lg">
        <h1>
          <netvs-icon class="shake-1s" icon="user_locked" size="3x"></netvs-icon>
        </h1>
        <h3>{{ $t('views.app.user_locked') }}</h3>
        <p>{{ $t('views.app.user_locked_text') }}</p>
        <pre>{{ $store.state.impersonate_user || $store.state.user }}</pre>
        <div class="container-fluid">
          <b-row>
            <b-col>
              <b-button variant="danger" block
                        @click="$store.commit('logout'); $bvModal.hide('netvs_user_locked');">
                <netvs-icon icon="sign_out"></netvs-icon>
                {{ $t('system.logout') }}
              </b-button>
            </b-col>
            <b-col v-if="$store.state.impersonate_user">
              <b-button variant="outline-secondary" block
                        @click="$store.commit('updateImpersonatingUser', null); $bvModal.hide('netvs_user_locked');">
                <netvs-icon icon="depersonate"></netvs-icon>
                {{ $t('components.svc_card.depersonate') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-modal>
      <b-modal content-class="text-center" centered id="net-suite-error" hide-footer hide-header size="lg">
        <h1>
          <netvs-icon class="shake-1s" icon="netvs_fatal_error" size="3x" @click="trigger_click"
                      ref="errorIcon"></netvs-icon>
        </h1>
        <h3 ref="wrongText">{{ $t('views.app.something_went_wrong') }}</h3>
        <p>{{ $t('views.app.its_not_your_mistake') }}</p>
        <CopyField class="shadow" variant="danger"
                   :text="global_error_sanitized"
                   height_override="60vh"
                   multiline/>
        <hr/>
        <a target="_blank" href="https://gitlab.kit.edu/scc-net/netvs/netvs-core/issues">
          <netvs-icon icon="bug"></netvs-icon>
          {{ $t('views.app.report_error') }}</a> |
        {{ $t('views.app.app_support') }} <a href="mailto:netvs@scc.kit.edu">{{ $t('system.netvs_scc_kit_edu') }}</a>
      </b-modal>
      <b-modal content-class="text-center" centered id="net-suite-update" hide-footer hide-header size="lg">
        <h1>
          <netvs-icon icon="update_new_version" size="3x"></netvs-icon>
        </h1>
        <h3>{{ $t('views.app.new_version_available') }}</h3>
        <p>{{ $t('views.app.please_reload_page') }}</p>
      </b-modal>
    </template>
    <template v-else>
      <b-alert variant="danger" fade show>
        <div class="text-center">
          <netvs-icon class="shake-1s" icon="netvs_fatal_error" size="6x" @click="trigger_click"
                      ref="errorIcon"></netvs-icon>
          <h1 ref="wrongText">{{ $t('views.app.something_went_wrong') }}</h1>
          <p>{{ $t('views.app.try_again_later') }}</p>
          <div v-if="$store.state.sys_alerts.length > 0">
            <hr/>
            <h2>{{ $t('system.alerts') }}</h2>
            <SystemAlertDisplay></SystemAlertDisplay>
          </div>
          <template v-if="$store.state.init_fail_info && typeof $store.state.init_fail_info === 'object'">
            <hr/>
            <h4>{{ $store.state.init_fail_info.error_type.description }}</h4>
            <p>{{ $store.state.init_fail_info.error.description }}</p>
            <code>```json
              {{ $store.state.init_fail_info.error.details }}
              ```</code>
          </template>
          <template v-else-if="$store.state.init_fail_info && typeof $store.state.init_fail_info === 'string'">
            <hr/>
            <p>{{ $store.state.init_fail_info }}</p>
          </template>
        </div>
      </b-alert>
    </template>
    <netvs-icon
        style="display: none;position: absolute; bottom: 0; transform: translate(-50%, 100vh); transition: transform 1s"
        ref="collector" icon="garbage_collector" size="10x"></netvs-icon>
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import CopyField from './components/CopyField'
import NetvsFooter from './components/Footer'
import Sidebar from './components/Sidebar'
import {EventBus} from '@/eventbus'
import SideNavbar from '@/components/SideNavbar'
import GlobalSearch from '@/components/GlobalSearch'
import config from '@/../netvs.config'
import SystemInfoService from './api-services/system_info.service'
import colorutil from '@/util/colorutil'
import SystemAlertDisplay from '@/components/SystemAlertDisplay.vue'
import SpecReady from '@/views/SpecReady.vue'
import dateutil from '@/util/dateutil'

export default {
  components: {SpecReady, SystemAlertDisplay, GlobalSearch, SideNavbar, Sidebar, NetvsFooter, Navbar, CopyField},
  data() {
    return {
      build_id: config.BUILD_ID,
      global_error: null,
      breadcrumbs: [],
      ready: false,
      colorblind_mode: false,
      typography_mode: false,
      translation_mode: false,
      last_logout: Date.now(),
      clicks: 0,
      important_items: [],
    }
  },
  watch: {
    $route() {
      let m = this.$router.currentRoute.matched
      m = m[m.length - 1]
      this.breadcrumbs = this.getBreadcrumbs(m)
      this.ready = this.$route.path === '/'
      this.$store.commit('setNavigationRefreshHandle', {gpk: null, objType: null})
    },
    '$store.state.locale': function () {
      // Watch the global language and redo the breadcrumbs, if the language has changed
      let m = this.$router.currentRoute.matched
      m = m[m.length - 1]
      this.breadcrumbs = this.getBreadcrumbs(m)
      this.ready = this.$route.path === '/'
      this.$store.commit('setNavigationRefreshHandle', {gpk: null, objType: null})
    },
    '$store.state.spec_ready': function () {
      if (!this.$store.state.spec_ready) {
        window.document.body.classList.add('theland')
      } else {
        window.document.body.classList.remove('theland')
      }
    }
  },
  computed: {
    global_error_sanitized() {
      if (this.global_error && this.$store.state.token) {
        return this.global_error.replace(this.$store.state.token.token, '<redacted>')
      } else {
        return this.global_error
      }
    }
  },
  methods: {
    checkVersion() {
      SystemInfoService.getAlerts().then((alerts) => {
        this.$store.commit('updateSystemAlerts', alerts.data)
      }).catch(() => {
      }) // Fail silently
      SystemInfoService.getMaintenance(this.$store.state).then((items) => {
        const alerts = []
        // Iterate over all maintenance announcements and add them to the alerts
        for (const item of items.data[0]) {
          // NOTE: The text generation is only a temporary solution until the api supports multiple languages
          alerts.push({
            title: {de: item.subject, en: item.subject},
            content:
                {
                  de: 'Von ' + dateutil.format_date(new Date(Date.parse(item.ts_begin))) + ' bis ' + dateutil.format_date(new Date(Date.parse(item.ts_end))) + ' wird es Wartungsarbeiten geben.',
                  en: 'From ' + dateutil.format_date(new Date(Date.parse(item.ts_begin))) + ' till ' + dateutil.format_date(new Date(Date.parse(item.ts_end))) + ' there will be maintenance.'
                }
          })
        }
        // Update the alerts
        this.$store.commit('updateMaintAlerts', alerts)
      }).catch(() => {
      }) // Fail silently
      SystemInfoService.getFrontendVersion().then((sysinfo) => {
        if (config.COMMIT_SHORT_SHA !== sysinfo.data.commit_short) {
          EventBus.$emit('update_available', sysinfo.data.version)
        }
      }).catch(() => {
      }) // Fail silently
    },
    getBreadcrumbs(m) {
      const res = [{
        text: m.meta.resolveName(this.$route.params),
        path: m.path,
        disabled: ('active' in m.meta ? !m.meta.active : false)
      }]
      while ((m.meta && m.meta.resolveParents) || m.parent) {
        if (m.meta && m.meta.resolveParents) {
          const parents = m.meta.resolveParents(this.$route.params)
          for (let i = 0; i < parents.length; i++) {
            if (typeof parents[i] === 'object' && 'text' in parents[i] && 'to' in parents[i]) {
              res.unshift(parents[i])
              continue
            }
            m = this.$router.resolve(parents[i]).resolved.matched
            m = m[m.length - 1]
            res.unshift({
              text: m.meta.resolveName(this.$route.params),
              to: parents[i],
              disabled: ('active' in m.meta ? !m.meta.active : false)
            })
          }
        } else {
          m = m.parent
          res.unshift({
            text: m.meta.resolveName(this.$route.params),
            to: m.path,
            disabled: ('active' in m.meta ? !m.meta.active : false)
          })
        }
      }
      return res
    },
    trigger_click() {
      this.clicks++
      if (this.clicks === 5) {
        document.onmousemove = (e) => {
          this.$refs.collector.$el.style.left = e.clientX + 'px'
        }
        document.body.style.height = '100vh'
        document.body.style.cursor = 'none'
        const cloud = this.$refs.errorIcon.$el
        document.body.style.overflow = 'hidden'
        cloud.style.transition = 'all 5s ease-in-out'
        cloud.style.transform = 'translate(0, -100vh)'
        this.$refs.wrongText.innerText = this.$t('views.app.something_went_very_wrong')
        this.$refs.collector.$el.style.transform = 'translate(-50%, 0)'
        this.$refs.collector.$el.style.display = 'block'
        setTimeout(() => {
          this.$refs.errorIcon.$el.classList.remove('shake-1s')
          this.add_item()
          setInterval(() => {
            for (let i = 0; i < this.important_items.length; i++) {
              this.important_items[i].y -= this.important_items[i].speed / 10
              this.important_items[i].el.style.bottom = this.important_items[i].y + 'vh'
              if (this.important_items[i].y < -10) {
                this.important_items[i].el.remove()
                this.important_items.splice(i, 1)
                i--
              }
            }
          }, 5)
        }, 1500)
      }
    },
    add_item() {
      const item = {
        el: this.$refs.errorIcon.$el.cloneNode(true),
        x: Math.random() * 100,
        y: 100,
        speed: Math.random() * 5 + 5,
      }
      item.el.style.transform = ''
      item.el.style.width = '100px'
      item.el.style.height = '100px'
      item.el.style.position = 'absolute'
      item.el.style.bottom = '100vh'
      item.el.style.left = item.x + 'vw'
      item.el.style.transition = 'none'
      document.body.appendChild(item.el)
      this.important_items.push(item)
      setTimeout(this.add_item, Math.random() * 500 + 100)
    }
  },
  mounted() {
    window.console.log('%c ', `padding: 73px 200px; background: url(${window.location.protocol}//${window.location.hostname}:${window.location.port}/important.webp) no-repeat; background-size: contain; line-height: 200px;`)
    const self = this
    if (this.$store.state.token && !this.$store.state.token.gpk) {
      this.$bvToast.toast(this.$t('system.incompatible_token'), {
        title: this.$t('system.incompatible_token_title'),
        variant: 'info',
        solid: true,
        autoHideDelay: 10000
      })
    }
    EventBus.$on('notify_user', (payload) => {
      this.$bvToast.toast(payload.body || '', {
        title: payload.title,
        variant: payload.variant || 'info',
        solid: payload.solid || true,
        autoHideDelay: payload.autoHideDelay || 10000
      })
    })
    EventBus.$on('rollback', (payload) => {
      this.$bvToast.toast(self.$t('system.rollback_event'), {
        title: self.$t('system.rollback_event_title'),
        variant: 'info',
        solid: true,
        autoHideDelay: 10000
      })
    })
    EventBus.$on('logout', (payload) => {
      window.console.debug(Date.now() - self.last_logout)
      if (Date.now() - self.last_logout < 1000) {
        return
      }
      self.last_logout = Date.now()
      this.$bvToast.toast(self.$t('system.logout_event'), {
        title: self.$t('system.logout_event_title'),
        variant: 'danger',
        solid: true,
        autoHideDelay: 10000
      })
    })
    EventBus.$on('update_available', (payload) => {
      window.console.debug('UPDATE!')
      self.$bvModal.show('net-suite-update')
    })
    EventBus.$on('overwrite_breadcrumb_name_of_url', (payload) => {
      for (let i = 0; i < this.breadcrumbs.length; i++) {
        if (this.breadcrumbs[i].to === payload.url) {
          this.breadcrumbs[i].text = payload.name
          return
        }
      }
    })
    EventBus.$on('overwrite_breadcrumbs', (payload) => {
      let m = this.$router.currentRoute.matched
      m = m[m.length - 1]
      if (payload instanceof Function) {
        m.meta.resolveParents = payload
      } else if (payload instanceof Object) {
        if ('parents' in payload) {
          m.meta.resolveParents = payload.parents
        }
        if ('name' in payload) {
          m.meta.resolveName = payload.name
        }
      }
      this.breadcrumbs = this.getBreadcrumbs(m)
    })
    EventBus.$on('user-locked', (payload) => {
      self.$bvModal.show('netvs_user_locked')
    })
    EventBus.$on('error', (payload) => {
      if (!payload) {
        window.console.debug('Received error with payload null/undefined')
        return
      }
      if ('response' in payload) {
        if (payload.response.status === 401) {
          return
        }
        if (payload.response.status === 400 && 'exception' in payload.response.data) {
          if (payload.response.data.exception.error_type.name === 'authentication_error' ||
              (payload.response.data.exception.error_type.code === -20010 && payload.response.data.exception.error.code === 4)) {
            return
          }
        }
        self.global_error = {failed_response: payload.response.data}
      } else {
        self.global_error = payload
      }
      if ('config' in payload && 'url' in payload.config && 'data' in payload.config) {
        self.global_error.request = {url: payload.config.url}
        try {
          self.global_error.data = JSON.parse(payload.config.data)
        } catch (e) {
          window.console.warn('Silenced JSON-Parse error. No request data available.')
        }
      }
      self.global_error.route_info = {
        path: this.$route.path,
      }
      self.global_error.session_info = {
        browser: {
          app_code_name: window.navigator.appCodeName,
          vendor: window.navigator.vendor,
          user_agent: window.navigator.userAgent
        }
      }
      self.global_error.netvs = {build_id: this.build_id, sysinfo: this.$store.state.sysinfo}
      if (this.$store.state.user) {
        self.global_error.session_info.login_name = this.$store.state.user.login_name
      } else {
        self.global_error.session_info.login_name = null
      }
      // Catch cyclic references
      try {
        self.global_error = JSON.stringify(self.global_error, null, 4)
      } catch (e) {
        window.console.error('Cyclic reference in error object; suppressed poo-storm.', self.global_error)
        self.global_error = 'Cyclic reference in error object; suppressed poo-storm.'
        return
      }
      self.global_error = '```json\n' + self.global_error + '\n```'
      this.$bvModal.show('net-suite-error')
    })
    EventBus.$on('set_colorblind_mode', (payload) => {
      this.colorblind_mode = payload
      this.$forceUpdate()
    })
    EventBus.$on('set_typography_mode', (payload) => {
      this.typography_mode = payload
      this.$forceUpdate()
    })
    EventBus.$on('set_translation_mode', (payload) => {
      this.translation_mode = payload
      this.$forceUpdate()
    })
    this.ready = window.location.pathname === '/' || window.location.pathname.trim() === ''
    this.checkVersion()
    setInterval(() => {
      this.checkVersion()
    }, 60000)
  },
  updated() {
    if (this.translation_mode) {
      document.onmousemove = (e) => {
        // get actively hovered element
        const el = document.elementFromPoint(e.clientX, e.clientY)
        // return if element is html or body
        if (el === document.body ||
            el === document.documentElement ||
            el.id === 'app' ||
            el.id === 'wrapper' ||
            el.id === 'page-content-wrapper' ||
            el.id === 'sideNavbar' ||
            el.classList.contains('sidebar-wrapper') ||
            el.classList.contains('container') ||
            el.childElementCount > 8) {
          return
        }
        let x = 0
        let y = 0
        const style = window.getComputedStyle(el)
        const matrix = style.transform || style.webkitTransform || style.mozTransform
        try {
          const matrixType = matrix.includes('3d') ? '3d' : '2d'
          const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

          if (matrixType === '2d') {
            x = matrixValues[4]
            y = matrixValues[5]
          } else if (matrixType === '3d') {
            x = matrixValues[12]
            y = matrixValues[13]
          }
        } catch (e) {
          // no transform
        }
        el.style.transition = 'transform 250ms'
        const dx = (e.clientX - (el.getBoundingClientRect().left + el.getBoundingClientRect().width / 2)) / el.getBoundingClientRect().width * 500
        const dy = (e.clientY - (el.getBoundingClientRect().top + el.getBoundingClientRect().height / 2)) / el.getBoundingClientRect().height * 500
        el.style.transform = `translate(${x - dx}px, ${y - dy}px)`
        window.console.log(el)
        setInterval(() => {
          el.style.transition = 'transform 1s'
          el.style.transform = 'translate(0, 0)'
        }, 800)
      }
    }
    if (this.colorblind_mode) {
      this.$nextTick(function () {
        const elements = document.querySelectorAll('*')
        elements.forEach((e) => {
          e.style.background = colorutil.random_color()
          e.style.color = colorutil.random_color()
        })
      })
    }
    if (this.typography_mode) {
      this.$nextTick(function () {
        const elements = document.querySelectorAll('p, a, button, li, h1, h2, h3, h4, h5, h6, span, label, input, select, textarea')
        elements.forEach((e) => {
          if (e.innerText && !e.style.transform.includes('scale')) {
            e.style.transform = `${e.style.transform} scale(${1 + Math.random() * 0.2 - 0.1})`
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import 'assets/css/variables.scss';
@import 'assets/css/netvs.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
@import '../node_modules/vis-network/dist/vis-network.min.css';

#wrapper {
  transition: padding-right .2s, padding-left .2s;
  padding-left: $left-sidebar-hidden-width;
}

#wrapper.squished-left {
  transition: padding-right .2s, padding-left .2s;
  padding-left: $left-sidebar-width;
}

@include media-breakpoint-down(md) {
  #wrapper,
  #wrapper.squished-left {
    transition: none;
    padding-left: 0;
  }
}

#wrapper.squished-right {
  padding-right: $right-sidebar-width;
}

#page-content-wrapper {
  margin-top: $navbar-height;
  padding-top: $spacer;
}

.container {
  max-width: 1600px;
}

.sidebar-wrapper {
  height: 100%;
  position: fixed;
  z-index: 666;
  top: 0;
  padding-top: $navbar-height;
  background: $sidebar-bg-color;
}

.sidebar-wrapper.left {
  transition: width .2s;
  left: 0;
  width: $left-sidebar-hidden-width;
}

.sidebar-wrapper.right {
  transition: right .2s;
  width: $right-sidebar-width;
  right: -$right-sidebar-width;
}

.sidebar-wrapper.show.left {
  width: $left-sidebar-width;
}

.sidebar-wrapper.show.right {
  right: 0;
}

@media screen and (max-width: 980px) {
  .sidebar-wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 420;
    top: -100%;
    transition: top .5s;
    padding-top: $navbar-height;
    background: $sidebar-bg-color;
  }

  .sidebar-wrapper.show {
    top: 0;
  }

  .sidebar-wrapper.right {
    width: 100%;
  }
}

.collapse-icon {
  transition: transform;
  transition-duration: 250ms;
}

.not-collapsed > .collapse-icon {
  transform: rotate(-180deg);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.theland {
  background-color: #ffff00;
}

.table-responsive {
  background: linear-gradient(
          to right,
          white 30%,
          rgba(255, 255, 255, 0)
  ) left center,
  linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          white 70%
  ) right center,
  linear-gradient(
          to right,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
  ) left center,
  linear-gradient(
          to left,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
  ) right center;

  background-repeat: no-repeat;
  background-size: 80px 100%, 80px 100%, 20px 120%, 20px 120%;
  background-attachment: local, local, scroll, scroll;
}
</style>
