<template>
  <b-modal v-if="object_definition != null"
           size="lg" scrollable :busy="object_definition == null"
           :id="modal_id"
           :title="functionWithObject2text(object_function, object_definition.name.replace(/^\w/, c => c.toUpperCase()) + (object_title==null?'':': ' + object_title) )"
           :static="false"
           ref="modal"
           @show="shown">
    <template #modal-footer="{ cancel }">
      <b-button @click="cancel()" variant="outline-secondary">{{ $t('system.cancel') }}</b-button>
      <b-button @click="commit(true)" :variant="'outline-'+function2variant(object_function)"
                v-if="object_function === 'create'">
        {{ function2text(object_function, 2).replace(/^\w/, c => c.toUpperCase()) }}
      </b-button>
      <b-button @click="commit(false)" :variant="function2variant(object_function)">
        {{ function2text(object_function).replace(/^\w/, c => c.toUpperCase()) }}
      </b-button>
    </template>
    <form style="min-height: 400px" ref="form" :id="modal_id + '-dbeditform'" v-if="object_definition != null"
          @submit.stop.prevent="commit(false)">
      <b-button type="submit" style="z-index: -1; position:absolute; opacity: 0" tabindex="-1">.
      </b-button>
      <APIObjectDBEditorBase
          :object_function="object_function"
          :object_fq_name="object_fq_name"
          :object_functions="object_functions"
          :object_definition_reference_names_by_attribute="object_definition_reference_names_by_attribute"
          :object_definition_referencing_by_attribute="object_definition_referencing_by_attribute"
          :object_definition="object_definition"
          :non_optionals_order="non_optionals_order"
          :non_optionals="non_optionals"
          :show_result="show_result"
          :old_data="old_data"
          :preview_fields="preview_fields"
          :presets="presets"
          :old_uuid="old_uuid"
          :object_title="object_title"
          :input_reducer="input_reducer"
          :nullable_non_expert="nullable_non_expert"
          @show_result_change="(p) => { show_result_int = p }"
      />
    </form>
  </b-modal>
</template>

<script>
import TransactionUtil from '@/util/transactionutil'
import {v4 as uuidv4} from 'uuid'
import APIObjectDBEditorBase from '@/components/db-editor/APIObjectDBEditorBase.vue'

export default {
  name: 'APIObjectDBEditor',
  components: {APIObjectDBEditorBase},
  data() {
    return {
      should_open: false,
      object_definition: null,
      object_definition_referencing_by_attribute: null,
      object_definition_reference_names_by_attribute: null,
      object_functions: null,
      show_result_int: false
    }
  },
  mounted() {
    const self = this
    this.$root.$on('bv::show::modal', (ev) => {
      if (ev === this.modal_id) {
        self.should_open = true
      }
    })
  },
  methods: {
    shown() {
      if (this.should_open) {
        this.should_open = false
      }
    },
    commit(keep) {
      const form = new FormData(document.getElementById(this.modal_id + '-dbeditform'))
      const elems = form.entries()
      const ta = {object_fq_name: this.object_fq_name, object_function: this.object_function}
      const new_params = {}
      for (const e of elems) {
        if (e[0].endsWith('_null')) {
          new_params[e[0].split('_null')[0]] = '__NULL'
        } else {
          if (e[0] in new_params && this.object_definition.attributes[e[0]].data_type.endsWith('_array')) {
            if (!Array.isArray(new_params[e[0]])) {
              new_params[e[0]] = [new_params[e[0]]]
            }
            new_params[e[0]].push(e[1])
          } else {
            new_params[e[0]] = e[1]
          }
        }
      }
      ta.parameters = {
        new: TransactionUtil.remove_empty_data(this.object_functions[this.object_function].parameters, new_params),
        old: this.old_data
      }
      ta.show_result = this.show_result_int
      ta.object_fq_name = this.object_fq_name
      ta.object_functions = this.object_functions
      ta.object_function = this.object_function
      ta.input_reducer = this.input_reducer
      ta.non_optionals_order = this.non_optionals_order
      ta.non_optionals = this.non_optionals
      ta.func_descr = this.object_functions[this.object_function].parameters
      ta.object_title = this.object_title
      let prev_fields = this.preview_fields
      if (prev_fields == null || this.object_function === 'update') {
        prev_fields = []
        if (this.object_function === 'update') {
          for (const data in this.object_functions[this.object_function].parameters) {
            if (data in ta.parameters.new && String(ta.parameters.old[data]) !== String(ta.parameters.new[data])) {
              prev_fields.push(data)
            }
          }
        } else {
          for (const data in this.object_functions[this.object_function].parameters) {
            prev_fields.push(data)
          }
        }
      }
      ta.preview_fields = prev_fields
      const wrapper_ta = {
        content: ta,
        variant: TransactionUtil.function2variant(ta.object_function),
        old_uuid: this.old_uuid,
        uuid: uuidv4(),
        ta_type: 'db_editor_simple'
      }
      if (!this.only_emit_ta) {
        this.$store.commit('addTransactionElement', wrapper_ta)
      }
      this.$emit('commited', ta)
      this.$emit('commited_wrapper', wrapper_ta)
      if (!keep) {
        this.$nextTick(() => {
          this.$bvModal.hide(this.modal_id)
        })
      }
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    function2variant: TransactionUtil.function2variant,
    function2text:
    TransactionUtil.function2text,
    functionWithObject2text:
    TransactionUtil.functionWithObject2text,
    async validateObjectFunction() {
      // TODO
    }
  },
  props: {
    object_fq_name: {
      required: true
    },
    modal_id: {
      type: String,
      required: true
    },
    object_function: {
      type: String,
      required: true
    },
    presets: {
      type: Object,
      default() {
        return {}
      }
    },
    input_reducer: {
      type: Object,
      default() {
        return {}
      }
    },
    old_data: {
      required: false,
      default() {
        return {}
      },
      type: Object
    },
    non_optionals: {
      required: false,
      default() {
        return []
      },
      type: Array
    },
    non_optionals_order: {
      required: false,
      type: Array
    },
    nullable_non_expert: {
      required: false,
      default() {
        return []
      },
      type: Array
    },
    old_uuid: {
      required: false,
      type: String,
      default() {
        return null
      }
    },
    preview_fields: {
      required: false,
      type: Array,
      default() {
        return null
      }
    },
    object_title: {
      required: false,
      default() {
        return null
      }
    },
    show_result: {
      default() {
        return false
      }
    },
    only_emit_ta: {
      default() {
        return false
      }
    }
  },
  computed: {
    computedPresets() {
      if (this.object_function === 'update') {
        return this.presets
      }
      if (this.presets) {
        const pres = {}
        Object.assign(pres, this.presets)
        for (const k in this.object_functions[this.object_function].parameters) {
          if ('new' in this.object_functions[this.object_function].parameters[k]) {
            if ('data_default' in this.object_functions[this.object_function].parameters[k].new && !(k in pres)) {
              pres[k] = this.object_functions[this.object_function].parameters[k].new.data_default
            }
          }
        }
        return pres
      }
      return {}
    }
  },
  watch: {
    $props: {
      immediate: true,
      deep: true,
      async handler() {
        this.show_result_int = this.show_result
        if (this.object_fq_name == null || (this.object_definition != null && this.object_fq_name === this.object_definition.fq_name)) {
          this.$emit('ready')
          if (this.should_open) {
            this.$root.$nextTick(() => this.$root.$emit('bv::show::modal', this.modal_id))
          }
          return
        }
        const res = await TransactionUtil.getObjectMetadata(this, this.object_fq_name)
        if (res === null) {
          return
        }
        this.object_definition = res.object_definition
        this.object_functions = res.object_functions
        this.object_definition_reference_names_by_attribute = res.object_definition_reference_names_by_attribute
        this.object_definition_referencing_by_attribute = res.object_definition_referencing_by_attribute
        this.$emit('ready')
        if (this.should_open) {
          this.$root.$nextTick(() => this.$root.$emit('bv::show::modal', this.modal_id))
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
