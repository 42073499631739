import transactionutil from '@/util/transactionutil'
import Jsontemplateutil from '@/util/jsontemplateutil'

export default {
  extract_permissions(permissions, descriptions) {
    const perms = {}
    for (const perm of permissions) {
      perms[perm.fq_name] = 'TODO'
    }
    return perms
  },
  dict_by_value_of_array(arr, val) {
    const res = {}
    for (let i = 0; i < arr.length; i++) {
      res[arr[i][val]] = arr[i]
    }
    return res
  },
  dict_of_lists_by_value_of_array(arr, val) {
    const res = {}
    for (let i = 0; i < arr.length; i++) {
      if (!(arr[i][val] in res)) {
        res[arr[i][val]] = []
      }
      res[arr[i][val]].push(arr[i])
    }
    return res
  },
  dict_of_lists_by_value_of_array_l2_l2_array(arr, val, val2) {
    const res = {}
    for (let i = 0; i < arr.length; i++) {
      const valval = arr[i][val]
      for (const j of valval) {
        if (!(j[val2] in res)) {
          res[j[val2]] = []
        }
        res[j[val2]].push(arr[i])
      }
    }
    return res
  },
  uniq_by(a, key = 'gpk') {
    var seen = {}
    return a.filter(function (item) {
      return Object.hasOwn(seen, item[key]) ? false : (seen[item[key]] = true)
    })
  },
  denullify_dict(dict) {
    const res = {}
    for (const key in dict) {
      if (dict[key] !== null) {
        res[key] = dict[key]
      }
    }
    return res
  },
  buildAPITaFromTaObjectArray(state, use_copy_with_errors = false) {
    const res = []
    // Use the ta_list copy with errors only when specified by the parameter
    const obj = (use_copy_with_errors) ? state.ta_list_errors : state.ta_list
    for (let i = 0; i < obj.length; i++) {
      const ta = obj[i].content
      if (obj[i].ta_type === 'db_editor_simple') {
        const app = { name: `${ta.object_fq_name}.${ta.object_function}` }
        if ('old' in ta.parameters && ta.parameters.old && Object.keys(ta.parameters.old).length > 0) {
          app.old = ta.object_functions === null ? ta.parameters.old : transactionutil.cast_to_json_type(state, ta.object_functions[ta.object_function].parameters, transactionutil.filter_old_data(ta.object_functions[ta.object_function].parameters, ta.parameters.old))
        }
        if (Object.keys(ta.parameters.new).length > 0) {
          app.new = transactionutil.cast_to_json_type(state, ta.object_functions[ta.object_function].parameters, transactionutil.filter_unchanged_data(ta.parameters.new, ta.parameters.old))
          window.console.debug(app.new)
        }
        app.idx = obj[i].uuid
        res.push(app)
      } else if (obj[i].ta_type === 'json_template') {
        // Apparently, JSON.parse(JSON.stringify()) is still the way to clone an JS entity.. the fuck
        const comp = Jsontemplateutil.compile_transaction(JSON.parse(JSON.stringify(ta.template)), ta.variables, obj[i].uuid)
        for (const d of comp) {
          res.push(d)
        }
      } else if (obj[i].ta_type.startsWith('db_editor_bundle_')) {
        const params = JSON.parse(JSON.stringify(ta.parameters.new))
        params._uuid = obj[i].uuid
        if (obj[i].ta_type.endsWith('prepend')) {
          const comp = Jsontemplateutil.compile_transaction(JSON.parse(JSON.stringify(ta.template)), params, obj[i].uuid)
          for (const d of comp) {
            res.push(d)
          }
        }
        const app = { name: `${ta.object_fq_name}.${ta.object_function}` }
        window.console.debug('db_editor_bundle', ta)
        if ('old' in ta.parameters && ta.parameters.old && Object.keys(ta.parameters.old).length > 0) {
          app.old = ta.object_functions === null ? ta.parameters.old : transactionutil.cast_to_json_type(state, ta.object_functions[ta.object_function].parameters, transactionutil.filter_old_data(ta.object_functions[ta.object_function].parameters, ta.parameters.old))
        }
        if (Object.keys(ta.parameters.new).length > 0) {
          app.new = transactionutil.cast_to_json_type(state, ta.object_functions[ta.object_function].parameters, transactionutil.remove_non_data_data(ta.object_functions[ta.object_function].parameters, transactionutil.filter_unchanged_data(ta.parameters.new, ta.parameters.old || {})))
          window.console.debug(app.new)
        }
        app.idx = obj[i].uuid
        res.push(app)
        if (obj[i].ta_type.endsWith('append')) {
          const comp = Jsontemplateutil.compile_transaction(JSON.parse(JSON.stringify(ta.template)), params, obj[i].uuid)
          for (const d of comp) {
            res.push(d)
          }
        }
      } else {
        window.console.warn(`Unknown transaction type ${obj[i].ta_type}!`)
      }
    }
    return res
  },
  getAPIErrorNameFromDBException(e) {
    if (typeof e !== 'object') {
      return -1
    }
    for (const t of e.traceback) {
      if ('param' in t && 'wapi.transaction_stmt.idx' in t.param) {
        return t.param['wapi.transaction_stmt.idx'][0].value
      }
    }
    return -1
  },
  checkPermission(state, permission) {
    return state.token && state.xup_perm != null && permission in state.xup_perm
  }
}
