export default {
  methods: {
    $t_netdb(item) {
      if (typeof item === 'object') {
        let target_lang = 'de'
        if (this.$store.state.locale in item) {
          const res = item[this.$store.state.locale]
          if (res && typeof res === 'object' && 'value' in res && res.value) {
            target_lang = this.$store.state.locale
          } else if (typeof res === 'string' && res) {
            target_lang = this.$store.state.locale
          }
        }
        if (!(target_lang in item) || !item[target_lang]) {
          window.console.debug('Missing translation', item)
          return '(missing translation, please report)'
        }
        const res = item[target_lang]
        if (typeof res === 'object' && 'value' in res) {
          return res.value
        }
        return res
      }
      return item
    }
  }
}
