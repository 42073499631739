<template>
  <footer>
    <hr>
    <p class="pull-right d-print-none">
      {{ $t('components.footer.support_application_and_api') }}: <a
      href="mailto:netvs@scc.kit.edu">{{ $t('system.netvs_scc_kit_edu') }}</a>
    </p>
    <p class="pull-left">{{ $t('components.footer.instance') }}: {{ $store.state.sysinfo.host_oper_mode.mode }} |
      <b-link v-b-modal.net-suite-version>{{ $t('system.version') }}</b-link>
      <span class="text-muted" v-if="$store.state.refreshHandleObjType"> | {{ $t('components.footer.current_object') }}: <b-link :to="'/object/'+$store.state.refreshHandleGPK">{{ $store.state.refreshHandleObjType }}:{{ $store.state.refreshHandleGPK }}</b-link></span>
    </p>
    <b-modal content-class="text-center" id="net-suite-version" hide-footer hide-header centered>
      <img class="img-fluid" alt="SCC" src="/logo.webp"/>
      <h3>{{ $t('system.netvs') }}<br/>"{{ $store.state.sysinfo.version_name }}"</h3>
      <p>
        {{ $t('system.build') }}: <code>{{ build_id }}</code>
      </p>
      <p>
        {{ $t('system.middleware') }}: <a
        :href="`https://gitlab.kit.edu/scc-net/netvs/netvs-core/commit/${$store.state.sysinfo.version}`"
        target="_blank">{{ $store.state.sysinfo.version }}</a>
      </p>
    </b-modal>
  </footer>
</template>

<script>
import config from '@/../netvs.config'

export default {
  name: 'NETVSFooter',
  data() {
    return {
      build_id: config.BUILD_ID
    }
  }
}
</script>

<style scoped>
</style>
