export default {
  format_vni(vni) {
    let res = vni.toString()
    res = ('000000' + res).slice(-6)
    let out = ''
    for (let i = 0; i < (res.length / 2); i++) {
      out += res.slice(i * 2, (i + 1) * 2)
      if (i + 1 < res.length / 2) {
        out += '.'
      }
    }
    return out
  },
  is_bcd_id(vni) {
    return /^([0-9]{2}\.[0-9]{2})(\.([0-9]{2}))?$/.test(vni)
  }
}
