// https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
// (modified)
// TODO: Refactor into global prototype definition file
// eslint-disable-next-line no-extend-native
String.prototype.toHSL = function (opts) {
  opts = opts || {}
  opts.hue = opts.hue || [0, 1239823]
  opts.sat = opts.sat || [75, 100]
  opts.lit = opts.lit || [40, 60]

  const range = function (hash, min, max) {
    const diff = max - min
    const x = ((hash % diff) + diff) % diff
    return x + min
  }

  let hash = 0
  if (this.length === 0) return hash
  for (let i = 0; i < this.length; i++) {
    hash = this.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }

  const h = range(hash, opts.hue[0], opts.hue[1])
  const s = range(hash, opts.sat[0], opts.sat[1])
  const l = range(hash, opts.lit[0], opts.lit[1])

  return `hsl(${h}, ${s}%, ${l}%)`
}

export default {
  random_color() {
    const makeColorCode = '0123456789ABCDEF'
    let code = '#'
    for (let count = 0; count < 6; count++) {
      code = code + makeColorCode[Math.floor(Math.random() * 16)]
    }
    return code
  }
}
