<template>
  <b-modal id="GlobalSearch" size="xl" v-model="show_search" :title="$t('components.global_search.global_search')"
           body-class="p-0" header-class="sticky-top bg-white"
           hide-footer @hidden="on_close_search">
    <template #modal-header>
      <b-form @submit.prevent="on_search_input_update(search_input_content)" class="w-100">
        <Typeahead
            ref="globalSearchBar"
            :data="search_history"
            v-model="search_input"
            :minMatchingChars="0"
            size="lg"
            :placeholder="$t('system.search') + ' & ' + $t('system.quick_navigation') + ($store.state.token !== null ? '' : ' (' + $t('components.global_search.not_signed_in') + ')')"
            :show_clear="false"
            :state="is_search_input_content_valid ? null : false"
            :autofocus="!search_input_content"
            @input="on_search_input_change"
            @select="on_search_input_update"
            :debounce="500"
            :sort="false"
            class="shadow">
          <template v-slot:prepend>
            <b-input-group-prepend v-if="$store.state.token">
              <b-input-group-text id="GlobalSearchInfo" tabindex=0
                                  :aria-label="$t('components.global_search.search_information')">
                <netvs-icon icon="help"></netvs-icon>
              </b-input-group-text>
              <b-popover target="GlobalSearchInfo" triggers="hover focus" placement="bottom"
                         custom-class="popover-wide">
                <div class="font-italic text-center p-1">
                  <template>
                    <i18n path="components.global_search.code_snippet.description" tag="p"
                          for="components.global_search.code_snippet" class="mb-0">
                      <template #regex_code>
                        <code>s/&lt;regex&gt;</code>
                        {{ $t('components.global_search.code_snippet.for_regex_search') }}<br>
                      </template>
                      <template #global_template>
                        <code>&lt;{{
                            $t('components.global_search.code_snippet.systemname_regex')
                          }}&gt;:&lt;{{ $t('components.global_search.code_snippet.search_regex') }}&gt;</code>
                        {{ $t('components.global_search.code_snippet.for_global_search_in_the_system') }}
                        ({{ $t('components.global_search.code_snippet.for_example') }}
                        <code>dnsvs:s/^kit.edu</code>)<br>
                      </template>
                      <template #list>
                        {{ valid_discriminators.join(', ') }}<br>
                      </template>
                    </i18n>
                  </template>
                </div>
              </b-popover>
            </b-input-group-prepend>
          </template>
        </Typeahead>
      </b-form>
      <div class="loading-indicator-container">
        <b-spinner variant="primary" small v-if="searching && is_search_input_content_valid"/>
      </div>
      <button class="close my-auto py-2" tabindex="-1" aria-label="Suche schließen"
              @click.stop="set_show_search(false)">×
      </button>
    </template>
    <p class="text-muted px-4 pt-4" v-if="search_results_term">
      {{
        $tc('components.global_search.result', search_results_count + filtered_pages.length, {
          count: search_results_count + filtered_pages.length,
          time: search_duration
        })
      }}
      <span class="float-right">
        <span v-for="type_badge in type_badges" :key="type_badge.type + '-count-badge'">
          <span class="nowrap">
          {{ type_badge.count }} <netvs-icon icon="close"/> <b-button class="badge type-badge" tabindex="-1"
                                                                      :class="{disabled: filter_type !== null && filter_type !== type_badge.type, selected: filter_type === type_badge.type}"
                                                                      @click="filter_by_type(type_badge.type)"
                                                                      :style="{background: type_badge.type.toHSL({lit: [30, 40]}), border: 0}">
            {{ type_badge.type }}
          </b-button>
          </span>
        </span>
      </span>
    </p>
    <PaginatorList v-if="suggestions.length > 0" hide-filter="true" hide-top-pagination="true" :items="suggestions">
      <template v-slot:item="data">
        <router-link class="p-3 pl-4 suggestion" :to="data.item.url"
                     style="text-decoration: none; color: inherit; overflow-wrap: break-word"
                     @click.native="show_search = false">
          <netvs-icon class="mr-2 suggestion-icon"
                      :icon="get_suggestion_icon(data.item.type)"></netvs-icon>
          <netvs-icon class="text-primary mr-2 suggestion-arrow"
                      icon="navigate"></netvs-icon>
          <b-badge class="mr-2" :style="{background: data.item.type.toHSL({lit: [30, 40]})}"
                   v-if="data.item.type !== 'page'">
            {{ data.item.type }}
          </b-badge>
          <span
              v-html="data.item.label.replace(new RegExp(data.item.type === 'page' ? escapeRegExp(search_input_content, search_input_content.startsWith('s/')) : escapeRegExp(search_results_term_no_desc, search_results_term_no_desc.startsWith('s/')), 'gi'), '<b>$&</b>')"/>
        </router-link>
      </template>
    </PaginatorList>
    <div class="p-3" v-else>
      <div
          class="font-italic text-center p-3">
        {{ $t('system.no_results') }}
        <template v-if="$store.state.token === null">({{ $t('components.global_search.your_not_signed_in') }})
        </template>
        <template v-else-if="this.search_input_content.length > 128">
          ({{ $t('components.global_search.search_length_exceeded') }})
        </template>
      </div>
    </div>
    <p class="text-muted text-center">
      <b-checkbox @change="on_search_input_update(search_input_content)" switch v-model="search_global"
                  :unchecked-value="false">{{ $t('components.global_search.search_globally') }}
      </b-checkbox>
      {{ $t('components.global_search.limit_api_calls_to') }}
      <b-form-select v-model="fetch_limit"
                     @input="limit_changed = true; on_search_input_update(search_input_content)"
                     :options="fetch_limit_options" style="width: 75px"/>
      {{ $tc('components.global_search.element', 2) }}.
    </p>
    <i18n v-if="!$route.path.startsWith('/launch/search')" class="text-muted text-center"
          path="components.global_search.tip_template" tag="p" for="components.global_search">
      <template #url>
        <code>{{ netvs_url }}/launch/search/&lt;{{ $t('components.global_search.term') }}&gt;</code>
      </template>
    </i18n>
  </b-modal>
</template>

<script>
import Mousetrap from 'mousetrap'
import SearchService from '@/api-services/search.service'
import PaginatorList from '@/components/PaginatorList'
import '@/util/colorutil'
import {EventBus} from '@/eventbus'
import Typeahead from '@/components/Typeahead'

export default {
  name: 'GlobalSearch',
  components: {Typeahead, PaginatorList},
  data() {
    return {
      netvs_url: window.location.protocol + '//' + window.location.host,
      modal_id: 'GlobalSearch',
      show_search: false,
      searching: false,
      search_input: '',
      search_input_content: '',
      search_duration: 0,
      search_results_term: null,
      search_results_count: 0,
      search_results: null,
      search_result_types: null,
      search_global: true,
      valid_discriminators: ['dnsvs', 'macauth', 'org', 'cntl'],
      fetch_limit: 25,
      fetch_limit_options: [
        5,
        25,
        50,
        100,
        500,
        {value: null, text: '∞⚠️'}
      ],
      limit_changed: false,
      filter_type: null
    }
  },
  computed: {
    search_history() {
      const hist = []
      for (let i = 0; i < this.$store.state.search_history.length; i++) {
        hist.push({icon: 'search', text: this.$store.state.search_history[i]})
      }
      return hist
    },
    is_search_input_content_valid() {
      return this.search_input_content.length <= 128 && this.$store.state.token
    },
    is_valid_direct_nav() {
      return false
    },
    pages() {
      const pages = [
        {
          name: this.$t('system.api_browser') + '(' + this.$t('system.swagger') + ')',
          url: '/swagger'
        },
        {
          name: this.$t('system.macvendor_search'),
          url: '/tools/oui_lookup'
        }
      ]
      if (this.$store.state.token !== null) {
        pages.push(...[
          {
            name: this.$t('system.dnsvs'),
            url: '/dnsvs/bcds'
          },
          {
            name: this.$t('components.global_search.your') + ' ' + this.$tc('system.bcd', 2),
            url: '/dnsvs/bcds'
          },
          {
            name: this.$t('components.global_search.your') + ' ' + this.$tc('system.domain', 2),
            url: '/dnsvs/fqdns'
          },
          {
            name: this.$tc('system.subaccount', 2) + ' & ' + this.$tc('system.api_token', 2),
            url: '/user/tokens'
          },
          {
            name: this.$t('system.macauth'),
            url: '/macauth'
          },
          {
            name: this.$tc('system.organizational_unit', 2) + ' (' + this.$t('system.OE') + ')',
            url: '/org/ou'
          },
          {
            name: this.$t('system.macfinder'),
            url: '/tools/macfinder'
          },
          {
            name: this.$tc('system.group', 2) + ' & ' + this.$tc('system.subgroup', 2),
            url: '/cntl/groups'
          }
        ])
      }
      return pages
    },
    filtered_pages() {
      return this.pages.filter(page => page.name.toLowerCase().includes(this.search_input_content.toLowerCase()))
    },
    suggestions() {
      const suggestions = []
      if (this.filter_type === null) {
        this.filtered_pages.forEach((page, i) => {
          suggestions.push({
            label: page.name, type: 'page', url: page.url
          })
        })
      }
      if (this.search_results === null || this.search_result_types === null) return suggestions
      this.search_results.forEach((results_array, i) => {
        const type = this.search_result_types[i]
        if (this.filter_type === null || this.filter_type === type) {
          results_array.forEach((result) => {
            if (type === null) {
              return
            }
            const suggestion = {result: result, type: type, label: 'THIS SHOULD NOT BE VISIBLE 😬', url: '#bug'}
            const labels = {
              'dns.record': result.fqdn + ' IN ' + result.type + ' ' + result.data,
              'dns.fqdn': result.value,
              'nd.ip_subnet': result.cidr,
              'nd.bcd': result.name,
              'nd.vlan': result.name + ' (' + result.id + '@' + result.net_instnc + ')',
              'cntl.group': result.name,
              'org.unit': result.name + ' (' + result.short_name + ')',
              'macauth.client': result.description + ' (' + result.mac_addr + ') in ' + result.bcd_name,
              'nd.vxlan': `${result.bcd} (VNI ${result.vni})`,
              'nd.device': `${result.fqdn}: ${result.type} (${result.nc})`,
            }
            const urls = {
              'dns.record': '/dnsvs/fqdns/' + result.fqdn + '/records',
              'dns.fqdn': '/dnsvs/fqdns/' + result.value,
              'nd.ip_subnet': '/dnsvs/bcds/' + result.bcd,
              'nd.bcd': '/dnsvs/bcds/' + result.name,
              'nd.vlan': '/dnsvs/bcds/' + result.bcd,
              'cntl.group': '/cntl/groups/' + result.name,
              'org.unit': '/org/ou/' + result.short_name,
              'nd.vxlan': '/dnsvs/bcds/' + result.bcd,
              'macauth.client': '/macauth/bcds/' + result.bcd_name,
              'nd.device': '/netdoc/devices/' + result.fqdn,
            }
            suggestion.label = labels[type]
            suggestion.url = urls[type]
            suggestions.push(suggestion)
          })
        }
      })

      return suggestions
    },
    type_badges() {
      if (!this.search_results) return []
      const badges_obj = {}
      this.search_results.forEach((array, index) => {
        badges_obj[this.search_result_types[index]] = (badges_obj[this.search_result_types[index]] || 0) + array.length
      })
      const badges = []
      for (const type in badges_obj) {
        if (badges_obj[type] > 0 && type !== 'null') badges.push({type: type, count: badges_obj[type]})
      }
      return badges
    },
    search_results_term_no_desc() {
      return this.search_results_term.indexOf(':') !== -1 ? this.search_results_term.split(':', 2)[1] : this.search_results_term
    }
  },
  methods: {
    set_show_search(show) {
      this.show_search = show
    },
    toggle_show_search() {
      this.set_show_search(!this.show_search)
    },
    on_search_input_change(input) {
      this.search_input_content = input
    },
    async on_search_input_update(input) {
      this.search_input = input
      if (input === '' || !this.is_search_input_content_valid) {
        return
      }
      if (input === 'do a barrel roll') {
        document.body.classList.add('barrel')
        if (this.bTimeout) clearTimeout(this.bTimeout)
        this.bTimeout = setTimeout(() => {
          document.body.classList.remove('barrel')
        }, 1000)
      } else if (input === 'colorblind') {
        EventBus.$emit('set_colorblind_mode', true)
      } else if (input === 'typography') {
        EventBus.$emit('set_typography_mode', true)
      } else if (input === 'translation') {
        EventBus.$emit('set_translation_mode', true)
      } else if (input === 'aprilfools') {
        this.$store.commit('setSpecReady', false)
      } else if (input === 'error') {
        throw new Error('Test error')
      }
      if (input.indexOf(':') !== -1) {
        const parts = input.split(':', 2)
        if (this.valid_discriminators.includes(parts[0].toLowerCase())) {
          if (parts[1] === '') {
            return
          }
          await this.dispatch_search(parts[1], parts[0])
          return
        }
      }
      this.searching = true
      this.$nextTick(() => {
        this.$refs.globalSearchBar.blur()
      })
      await this.dispatch_search(input)
    },
    async dispatch_search(term, discriminator = null) {
      window.console.log('Dispatching search for:', term, 'with discriminator:', discriminator)
      this.$store.commit('push_search_history', term)
      const request_time = Date.now()
      const search_request = await SearchService.searchGlobal(this, term, discriminator, this.fetch_limit, this.search_global ? null : true)
      search_request.promise.then((response) => {
        if (!this.limit_changed) {
          this.filter_type = null
        }
        this.limit_changed = false
        if (search_request.term !== this.search_input_content) {
          window.console.log(`Search response for term ${search_request.term} out of date. Dropping...`)
          return
        }
        this.search_results_term = search_request.term
        let count = 0
        for (let i = 0; i < response.data.length; i++) {
          if (search_request.types[i]) {
            const seen = {}
            response.data[i] = response.data[i].filter((item) => {
              // eslint-disable-next-line no-prototype-builtins
              return seen.hasOwnProperty(item.gpk) ? false : (seen[item.gpk] = true)
            })
            count += response.data[i].length
          }
        }
        this.search_results_count = count
        this.search_result_types = search_request.types
        this.search_results = response.data
        this.search_duration = (Date.now() - request_time) / 1000
        this.searching = false
      }).catch((reason) => {
        window.console.log(reason)
      })
    },
    on_close_search() {
      this.search_input = ''
      this.search_input_content = ''
      this.search_results = null
      this.search_duration = 0
      this.search_results_count = 0
      this.search_results_term = null
    },
    get_suggestion_icon(type) {
      if (type === 'page') {
        return 'link'
      }
      if (type.startsWith('dns.')) {
        return 'dnsvs'
      }
      if (type === 'nd.vlan') {
        return 'vlan'
      }
      if (type === 'nd.vxlan') {
        return 'vni'
      }
      if (type.startsWith('nd.')) {
        return 'netdoc'
      }
      if (type.startsWith('org.')) {
        return 'orgs'
      }
      if (type === 'cntl.group') {
        return 'groups'
      }
      if (type.startsWith('cntl.')) {
        return 'cntl'
      }
      if (type === 'nd.device') {
        return 'device'
      }
      return 'unknown'
    },
    filter_by_type(type) {
      if (this.filter_type !== type) {
        this.filter_type = type
      } else {
        this.filter_type = null
      }
    },
    escapeRegExp: SearchService.escapeRegExp
  },
  watch: {
    $route() {
      if (this.$route.path.startsWith('/launch/search')) {
        this.set_show_search(true)
        if ('pathMatch' in this.$route.params) {
          this.on_search_input_update(this.$route.params.pathMatch)
          this.on_search_input_change(this.$route.params.pathMatch)
        }
      }
    }
  },
  mounted() {
    const self = this
    Mousetrap.bind(['/', 'command+k', 'ctrl+k'], function (e) {
      e.preventDefault()
      self.set_show_search(true)
    })
    this.$root.$on('show_search', () => {
      this.set_show_search(true)
    })
  }
}
</script>

<style scoped lang="scss">
@import '../assets/css/variables.scss';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

.loading-indicator-container {
  position: relative;
  width: 0;
  margin: auto 0;
}

.loading-indicator-container > span {
  position: relative;
  right: 30px;
  z-index: 3;
}

.suggestion {
  display: block;
}

.suggestion:nth-child(even) {
  background: #f1f1f1;
}

.suggestion:last-child {
  margin-bottom: $spacer;
}

.suggestion:hover,
.suggestion:focus {
  background: #d7d7d7;
}

.suggestion-icon {
  width: 16px;
}

.suggestion-arrow {
  display: none;
  width: 16px;
}

.suggestion:hover > .suggestion-arrow,
.suggestion:focus > .suggestion-arrow {
  display: initial;
}

.suggestion:hover > .suggestion-icon,
.suggestion:focus > .suggestion-icon {
  display: none;
}

.type-badge {
  transition: padding 250ms;
}

.type-badge.selected {
  padding: 7px;
}
</style>
