import {library} from '@fortawesome/fontawesome-svg-core'

import {
  faShieldHalved,
  faExclamationTriangle,
  faCircleNotch,
  faFilter,
  faCircleInfo,
  faBars,
  faArrowRight,
  faSort,
  faSortUp,
  faSortDown,
  faCode,
  faBug,
  faPlus,
  faChevronUp,
  faChevronDown,
  faCopy,
  faArrowRotateLeft,
  faArrowRotateRight,
  faCaretUp,
  faCaretDown,
  faVial,
  faCheckCircle,
  faMagnifyingGlass,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faUser,
  faUserNinja,
  faEye,
  faEyeSlash,
  faLink,
  faQuestion,
  faXmark,
  faChevronLeft,
  faUsersCog,
  faBuilding,
  faUsers,
  faDumpsterFire,
  faLinkSlash,
  faFileImport,
  faFileExport,
  faRadiation,
  faSignsPost,
  faNetworkWired,
  faClockRotateLeft,
  faEthernet,
  faPooStorm,
  faTools,
  faArrowsRotate,
  faBell,
  faGift,
  faPlay,
  faThumbsUp,
  faThumbsDown,
  faGear,
  faTowerBroadcast,
  faCheck,
  faBolt,
  faQrcode,
  faScroll,
  faAnglesLeft,
  faAnglesRight,
  faLock,
  faFire,
  faListOl,
  faMagnifyingGlassLocation,
  faIndustryWindows,
  faEnvelope,
  faRocket,
  faPaperPlane,
  faLifeRing,
  faRocketLaunch,
  faBlockBrickFire,
  faUsersBetweenLines,
  faKey,
  faLanguage,
  faLeftLongToLine,
  faStarShooting,
  faToilet,
  faToolbox,
  faCodeSimple,
  faUserRobot,
  faHouse,
  faExternalLink,
  faGrid2,
  faList,
  faListTree,
  faArrowDownFromLine,
  faArrowUpFromLine,
  faPlanetRinged,
  faDoorOpen,
  faServer,
  faOutlet,
  faArrowRightToArc,
  faComment,
  faMaximize,
  faMinimize,
  faMapLocationDot,
  faLocationDot,
  faHose,
  faUserPlus
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faExclamationTriangle,
  faCircleNotch,
  faFilter,
  faCircleInfo,
  faBars,
  faArrowRight,
  faSort,
  faSortUp,
  faSortDown,
  faCode,
  faBug,
  faPlus,
  faChevronUp,
  faChevronDown,
  faCopy,
  faArrowRotateRight,
  faArrowRotateLeft,
  faCaretUp,
  faCaretDown,
  faVial,
  faCheckCircle,
  faMagnifyingGlass,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faUser,
  faUserNinja,
  faEye,
  faEyeSlash,
  faLink,
  faQuestion,
  faXmark,
  faChevronLeft,
  faUsersCog,
  faBuilding,
  faUsers,
  faDumpsterFire,
  faLinkSlash,
  faFileImport,
  faFileExport,
  faRadiation,
  faSignsPost,
  faNetworkWired,
  faClockRotateLeft,
  faEthernet,
  faPooStorm,
  faTools,
  faArrowsRotate,
  faBell,
  faGift,
  faPlay,
  faThumbsUp,
  faThumbsDown,
  faGear,
  faTowerBroadcast,
  faCheck,
  faBolt,
  faQrcode,
  faScroll,
  faAnglesLeft,
  faAnglesRight,
  faLock,
  faFire,
  faListOl,
  faMagnifyingGlassLocation,
  faIndustryWindows,
  faEnvelope,
  faRocket,
  faPaperPlane,
  faLifeRing,
  faRocketLaunch,
  faBlockBrickFire,
  faUsersBetweenLines,
  faKey,
  faLanguage,
  faLeftLongToLine,
  faStarShooting,
  faToilet,
  faToolbox,
  faShieldHalved,
  faCodeSimple,
  faUserRobot,
  faHouse,
  faExternalLink,
  faGrid2,
  faList,
  faListTree,
  faArrowDownFromLine,
  faArrowUpFromLine,
  faPlanetRinged,
  faHouse,
  faDoorOpen,
  faServer,
  faOutlet,
  faArrowRightToArc,
  faComment,
  faMaximize,
  faMinimize,
  faMapLocationDot,
  faLocationDot,
  faHose,
  faUserPlus
)
