import Axios from 'axios'
import config from '@/../netvs.config'
import TransactionService from '@/api-services/transaction.service'

const LOGIN_RESOURCE = '/api/login'
const OIC_RECEIVER = '/api/oic_create_token'

export default {
  login (username, password, stay_logged_in) {
    if (config.ENABLE_OIC) {
      return Axios.post(LOGIN_RESOURCE, {stay_logged_in: stay_logged_in})
    }
    return Axios.post(LOGIN_RESOURCE, { username: username, password: password })
  },
  receive_netdb_token(payload) {
    return Axios.get(OIC_RECEIVER + payload)
  },
  logout (config) {
    // Important: Do not rename the idx property, it is used in the interceptor to detect the logout request
    const ta = [
      { idx: 'doLogoutRequest', name: 'cntl.wapi_auth.delete', old: { gpk: config.token.gpk } }
    ]
    return TransactionService.execute(config, ta)
  },
  get_mgr(config, mgr_login_name) {
    const ta = [
      { name: 'cntl.mgr.list', old: { login_name: mgr_login_name } }
    ]
    return TransactionService.execute(config, ta)
  }
}
