import Axios from 'axios'
import config from '@/../netvs.config'
import Transactionutil from '@/util/transactionutil'
import Apiutil from '@/util/apiutil'

const RESOURCE_NAME_TA = `${config.NETDB_API_BASE_URL}/${config.NETDB_API_VERSION}/wapi/transaction/execute`

export default {
  execute(state, ta, dictify = true) {
    let config = (state && 'netdb_axios_config' in state) ? state.netdb_axios_config : state
    if (!config) {
      config = {headers: {Authorization: 'Bearer dummy'}}
    }
    config.headers['Content-Type'] = 'application/json; charset=utf-8'
    if (dictify) {
      return new Promise((resolve, reject) => {
        Axios.post(RESOURCE_NAME_TA + '?dict_mode=true' + (state.impersonate_user != null ? '&su_login_name=' + encodeURIComponent(state.impersonate_user) : ''), Transactionutil.dictifyAndEnrichTa(ta, state), config).then((response) => {
          if (typeof response.data === 'object') {
            for (const itm in response.data) {
              if (itm.startsWith('__lang_')) {
                const enrich_key = itm.replace('__lang_', '')
                if (enrich_key in response.data) {
                  const lang_dat = Apiutil.dict_of_lists_by_value_of_array(response.data[itm], 'object_gfk')
                  delete response.data[itm]
                  for (let i = 0; i < response.data[enrich_key].length; i++) {
                    const line = response.data[enrich_key][i]
                    const obj_lang_dat_by_key_word = Apiutil.dict_of_lists_by_value_of_array(lang_dat[line.gpk], 'ot_lang_attr_def_key_word')
                    for (const k in obj_lang_dat_by_key_word) {
                      response.data[enrich_key][i][k] = Apiutil.dict_by_value_of_array(obj_lang_dat_by_key_word[k], 'language_tag')
                    }
                  }
                }
              }
            }
          }
          resolve(response)
        }).catch((error) => {
          reject(error)
        })
      })
    } else {
      return Axios.post(RESOURCE_NAME_TA + '?dict_mode=true' + (state.impersonate_user != null ? '&su_login_name=' + encodeURIComponent(state.impersonate_user) : ''), ta, config)
    }
  },
  executeList(state, ta) {
    const config = ('netdb_axios_config' in state) ? state.netdb_axios_config : state
    config.headers['Content-Type'] = 'application/json; charset=utf-8'
    return Axios.post(RESOURCE_NAME_TA + '?dict_mode=false' + (state.impersonate_user != null ? '&su_login_name=' + encodeURIComponent(state.impersonate_user) : ''), ta, config)
  },
  executeDry(state, ta) {
    const config = ('netdb_axios_config' in state) ? state.netdb_axios_config : state
    config.headers['Content-Type'] = 'application/json; charset=utf-8'
    return Axios.post(RESOURCE_NAME_TA + '?dict_mode=true&dry_mode=true' + (state.impersonate_user != null ? '&su_login_name=' + encodeURIComponent(state.impersonate_user) : ''), ta, config)
  },
  executeDrySu(state, ta, user) {
    const config = ('netdb_axios_config' in state) ? state.netdb_axios_config : state
    config.headers['Content-Type'] = 'application/json; charset=utf-8'
    return Axios.post(RESOURCE_NAME_TA + '?dict_mode=true&dry_mode=true&su_login_name=' + encodeURIComponent(user), ta, config)
  }
}
