<template>
  <b-modal :id="modal_id" :title="title">
    <b-form :id="`${modal_id}-jsoneditform`" @submit.stop.prevent="commit()" ref="form">
      <b-button type="submit" style="z-index: -1; position:absolute; opacity: 0" tabindex="-1">.
      </b-button>
      <div v-for="(v, id) in variables" :key="v.id">
        <JSONTemplateDataEditFieldList v-if="'list' in v && v.list" :variable="v" :variable_id="id"
                                       :value="bound_variables[id]"/>
        <JSONTemplateDataEditField v-else :value="bound_variables[id]" :variable="v" :variable_id="id"/>
      </div>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button @click="cancel()" variant="outline-secondary">{{ $t('system.cancel') }}</b-button>
      <b-button :class="!isValid?'shake-1s':''" @click="commit()" :variant="function2variant(template.display_variant)">
        {{ function2text(template.display_variant).replace(/^\w/, c => c.toUpperCase()) }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import JSONTemplateDataEditField from '@/components/db-editor/JSONTemplateDataEditField.vue'
import JSONTemplateDataEditFieldList from '@/components/db-editor/JSONTemplateDataEditFieldList.vue'
import TransactionUtil from '@/util/transactionutil'
import {v4 as uuidv4} from 'uuid'
import JsonTemplateUtil from '@/util/jsontemplateutil'

export default {
  name: 'JSONTemplateTransactionGroupEditor',
  components: {JSONTemplateDataEditField, JSONTemplateDataEditFieldList},
  data() {
    return {
      isValid: true
    }
  },
  props: {
    modal_id: {
      type: String,
      required: true
    },
    template: {
      type: Object,
      required: false,
      default() {
        return null
      },
      validator: async function (v) {
        if (v === null) {
          return true
        }
        return (await JsonTemplateUtil.validate_template(v)).status
      }
    },
    bound_variables: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    old_uuid: {
      required: false,
      type: String,
      default() {
        return null
      }
    },
  },
  computed: {
    title() {
      if (this.template && 'name' in this.template) {
        return this.template.name[this.$store.state.locale]
      }
      return ''
    },
    variables() {
      if (this.template && 'variables' in this.template) {
        return this.template.variables
      }
      return {}
    },
  },
  methods: {
    function2variant: TransactionUtil.function2variant,
    function2text: TransactionUtil.function2text,
    commit() {
      this.isValid = true
      const form = new FormData(document.getElementById(`${this.modal_id}-jsoneditform`))
      const elems = form.entries()
      const ta = {template: this.template}
      const variables = {}
      for (const e of elems) {
        if (e[0].endsWith('_null')) {
          variables[e[0].split('_null')[0]] = '__NULL'
        } else {
          if (e[0] in variables) {
            if (!Array.isArray(variables[e[0]])) {
              variables[e[0]] = [variables[e[0]]]
            }
            variables[e[0]].push(e[1])
          } else {
            window.console.debug(ta.template)
            if (ta.template.variables[e[0]].list) {
              variables[e[0]] = [e[1]]
            } else {
              variables[e[0]] = e[1]
            }
          }
        }
        if (!ta.template.variables[e[0]].optional && !e[1]) {
          this.$nextTick(() => {
            this.$bvToast.toast(this.$t('components.data_edit_field.feedback_content_required_template', {
              field: ta.template.variables[e[0]].friendly_name[this.$store.state.locale]
            }), {
              title: this.$t('components.data_edit_field.feedback_content_required'),
              variant: 'danger',
              solid: true
            })
            this.isValid = false
          })
          return
        }
      }
      ta.variables = variables
      const wrapper_ta = {
        content: ta,
        variant: TransactionUtil.function2variant(this.template.display_variant),
        old_uuid: this.old_uuid,
        uuid: uuidv4(),
        ta_type: 'json_template'
      }
      window.console.debug('commit', wrapper_ta)
      this.$store.commit('addTransactionElement', wrapper_ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
      this.$nextTick(() => {
        this.$bvModal.hide(this.modal_id)
      })
    }
  }
}
</script>

<style scoped>

</style>
