const punycode = require('punycode/')

export default {
  normalize_fdqn(data) {
    // Check if the input is a string
    if (typeof data !== 'string') {
      return ''
    }
    data = punycode.toASCII(data.toLowerCase())

    if (!data.endsWith('.')) {
      // Add the missing trailing dot
      data += '.'
    }

    // Test if the string contains only valid lower case and printable characters valid for fqdn usage
    const validChars = /^([a-z0-9-._*]+)$/g
    if (!validChars.test(data)) {
      return ''
    }

    // Check if the FQDN is not too long
    if (data.length > 255) {
      return ''
    }

    // Return data if everything is valid
    return data
  }
}
