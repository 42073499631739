import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/i18n'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'personal_home',
      component: () => import('./views/PersonalHome.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.home')
        }
      }
    },
    {
      path: '/home',
      name: 'Home',
      component: () => import('./views/Home.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.home')
        }
      }
    },
    {
      path: '/lab/colors',
      name: 'colors',
      meta: {
        resolveName: function () {
          return 'PaletteTest'
        }
      },
      component: () => import('./views/tools/PaletteTest.vue'),
    },
    {
      path: '/swagger',
      name: 'swagger-ui',
      component: () => import('./views/Swagger.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.api_browser')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/launch/:component',
      name: 'launchpad',
      component: () => import('./views/Launchpad.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.launchpad')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/launch/:component/:pathMatch(.*)*',
      name: 'launchpad_payload',
      component: () => import('./views/Launchpad.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.launchpad')
        }
      }
    },
    {
      path: '/org/ou',
      name: 'ous',
      component: () => import('./views/org/OU'),
      meta: {
        resolveName: function () {
          return i18n.tc('system.organizational_unit', 2)
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/org/ou/:ou',
      name: 'ou',
      component: () => import('./views/org/OU'),
      meta: {
        resolveName: function (query) {
          return query.ou.toUpperCase()
        },
      }
    },
    {
      path: '/org/type',
      name: 'ou_types',
      component: () => import('./views/org/OUTypes'),
      meta: {
        resolveName: function () {
          return i18n.tc('system.organizational_unit_type', 2)
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/cntl/bcds/:bcd_name',
      name: 'cntl_bcd',
      component: () => import('./views/GenericBCDView.vue'),
      meta: {
        resolveName: function (query) {
          return query.bcd_name
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/cntl/groups',
      name: 'groups',
      component: () => import('./views/cntl/Groups'),
      meta: {
        resolveName: function () {
          return i18n.tc('system.group', 2)
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/cntl/groups/:group',
      name: 'group',
      component: () => import('./views/cntl/Groups'),
      meta: {
        resolveName: function (query) {
          return query.group.toUpperCase()
        },
        resolveParents: function (query) {
          // const res = OUService.list(Vue.$store.state, {do_rootpath: true, short_name: query.ou})
          return ['/cntl/groups']
        }
      }
    },
    {
      path: '/cntl/svc-accounts',
      name: 'svc-accounts',
      component: () => import('./views/cntl/SVCAccounts'),
      meta: {
        resolveName: function () {
          return i18n.tc('components.svc_card.svc_account', 2)
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/cntl/svc-accounts/:account_name',
      name: 'svc-account',
      component: () => import('./views/cntl/SVCAccounts'),
      meta: {
        resolveName: function (query) {
          return query.account_name
        },
        resolveParents: function (query) {
          return ['/cntl/svc-accounts']
        }
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.login')
        }
      }
    },
    {
      path: '/oic_login',
      name: 'login_oic',
      component: () => import('./views/OICLogin.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.oic_login')
        }
      }
    },
    {
      path: '/user/tokens',
      name: 'tokens',
      component: () => import('./views/netdb/Tokens.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.accounts_tokens')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/macauth/',
      name: 'macauth_redir',
      redirect: '/macauth/bcds',
    },
    {
      path: '/macauth/bcds',
      name: 'macauth',
      component: () => import('./views/macauth/MACAuthOverview.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.macauth')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/dhcp-leases/',
      name: 'dhcp_leases_redir',
      redirect: '/dhcp-leases/bcds',
    },
    {
      path: '/dhcp-leases/bcds',
      name: 'dhcp_leases',
      component: () => import('./views/dhcp_leases/Overview.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.dhcp_leases')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/:system/bcds/:bcd_name',
      component: () => import('./views/GenericBCDView.vue'),
      meta: {
        resolveName: function (query) {
          return query.bcd_name
        },
        resolveParents: function (query) {
          return [`/${query.system}/bcds`]
        }
      }
    },
    {
      path: '/netdoc/',
      name: 'netdoc',
      redirect: '/netdoc/bcds',
    },
    {
      path: '/netdoc/sites',
      name: 'netdoc_sites_overview',
      component: () => import('./views/nd/NetdocSiteOverview'),
      meta: {
        active: true,
        resolveName: function (query) {
          return i18n.tc('views.nd.netdoc.sites', 2)
        },
        resolveParents: function (query) {
          return [{text: i18n.t('system.netdoc'), to: '/netdoc'}, '/']
        }
      }
    },
    {
      path: '/netdoc/sites/:site',
      name: 'netdoc_site',
      component: () => import('./views/nd/NetdocSite'),
      meta: {
        active: true,
        resolveName: function (query) {
          // TODO
          return query.site
        },
        resolveParents: function (query) {
          return ['/netdoc/sites']
        }
      }
    },
    {
      path: '/netdoc/buildings',
      name: 'netdoc_building_overview',
      component: () => import('./views/nd/NetdocSite'),
      meta: {
        active: false,
        resolveName: function (query) {
          // TODO
          return i18n.tc('views.nd.netdoc.bldgs', 2)
        },
        resolveParents: function (query) {
          return [{text: i18n.tc('views.nd.netdoc.sites', 2), to: '/netdoc/sites'}, '/']
        }
      }
    },
    {
      path: '/netdoc/sites/:site/:bldg',
      name: 'netdoc_bldg',
      component: () => import('./views/nd/NetdocBldg'),
      meta: {
        active: true,
        resolveName: function (query) {
          // TODO
          return query.bldg
        },
        resolveParents: function (query) {
          return ['/netdoc/sites/' + query.site]
        }
      }
    },
    {
      path: '/netdoc/sites/:site/:bldg/:room_gpk',
      name: 'netdoc_bldg',
      component: () => import('./views/nd/NetdocRoom'),
      meta: {
        active: true,
        resolveName: function (query) {
          // TODO
          return query.room_gpk
        },
        resolveParents: function (query) {
          return ['/netdoc/sites/' + query.site + '/' + query.bldg]
        }
      }
    },
    {
      path: '/netdoc/bcds',
      name: 'netdoc_bcds',
      // TODO:
      component: () => import('./views/nd/NetdocBCDOverview'),
      meta: {
        resolveName: function () {
          return i18n.tc('system.bcd', 2)
        },
        resolveParents: function (query) {
          return [{text: i18n.t('system.netdoc'), to: '/netdoc'}, '/']
        }
      }
    },
    {
      path: '/netdoc/modules',
      name: 'netdoc_modules_overview',
      meta: {
        active: false,
        resolveName: function (query) {
          return i18n.tc('views.nd.netdoc.module', 2)
        },
        resolveParents: function (query) {
          return [{text: i18n.t('system.netdoc'), to: '/netdoc'}, '/']
        }
      }
    },
    {
      path: '/netdoc/modules/:module_gpk/p_port/:p_port_gpk',
      name: 'netdoc_p_port',
      component: () => import('./views/nd/NetdocPPort'),
      meta: {
        resolveName: function (query) {
          return query.p_port_gpk
        },
        resolveParents: function (query) {
          return ['/netdoc/modules/' + query.module_gpk]
        }
      }
    },
    {
      path: '/netdoc/modules/:module_gpk',
      name: 'netdoc_module',
      component: () => import('./views/nd/NetdocModule'),
      meta: {
        resolveName: function (query) {
          return query.module_gpk
        },
        resolveParents: function (query) {
          return ['/netdoc/modules']
        }
      }
    },
    {
      path: '/netdoc/devices',
      name: 'netdoc_device_overview',
      meta: {
        active: false,
        resolveName: function (query) {
          return i18n.tc('views.nd.netdoc.device', 2)
        },
        resolveParents: function (query) {
          return [{text: i18n.t('system.netdoc'), to: '/netdoc'}, '/']
        }
      }
    },
    {
      path: '/netdoc/devices/:fq_name',
      name: 'netdoc_device',
      component: () => import('./views/nd/NetdocDevice'),
      meta: {
        resolveName: function (query) {
          return query.fq_name
        },
        resolveParents: function (query) {
          return ['/netdoc/devices']
        }
      }
    },
    {
      path: '/netdoc/map',
      name: 'map',
      component: () => import('./views/nd/Map.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.campus_map')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/tools/hub',
      name: 'netvshub',
      component: () => import('./views/tools/NETVSHub.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.netvs_hub')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/tools/macfinder',
      name: 'macfinder',
      component: () => import('./views/tools/MACfinder.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.macfinder')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/tools/oui_lookup',
      name: 'ouilookup',
      component: () => import('./views/tools/OUILookup'),
      meta: {
        resolveName: function () {
          return i18n.t('system.macvendor_search')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/tools/ap_list',
      name: 'ap_list',
      component: () => import('./views/tools/APList'),
      meta: {
        resolveName: function () {
          return i18n.t('system.ap_list')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/tools/ip',
      name: 'ip_tools',
      component: () => import('./views/tools/IPv6'),
      meta: {
        resolveName: function () {
          return i18n.t('system.ip_tools')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/tools/ip_contact',
      name: 'ip_contact',
      component: () => import('./views/tools/IPContact'),
      meta: {
        resolveName: function () {
          return i18n.t('system.ip_contact')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/tools/domain_finder',
      name: 'domain_finder',
      component: () => import('./views/tools/DomainFinder'),
      meta: {
        resolveName: function () {
          return i18n.t('system.domain_finder')
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/requests',
      name: 'requests',
      component: () => import('./views/requests/Requests'),
      meta: {
        resolveName: function () {
          return i18n.tc('system.request', 2)
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/object/:gpk',
      component: () => import('./views/ObjectExplorer.vue'),
      meta: {
        resolveName: function (query) {
          return query.gpk
        },
        resolveParents: function (query) {
          return ['/']
        }
      }
    },
    {
      path: '/dnsvs',
      component: () => import('./views/dnsvs/Main.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.dnsvs')
        },
        resolveParents: function (query) {
          return ['/']
        }
      },
      children: [
        {
          path: '', beforeEnter: (to, from, next) => next('/dnsvs/bcds')
        },
        {
          path: 'fqdns',
          name: 'fqdns',
          component: () => import('./views/dnsvs/DNSVSEntry.vue'),
          meta: {
            resolveName: function () {
              return i18n.t('system.fqdns')
            }
          }
        },
        {
          path: 'fqdns/:fqdn',
          name: 'DomainInspect',
          component: () => import('./views/dnsvs/DomainInspect.vue'),
          meta: {
            resolveName: function (query) {
              return (query.fqdn === '$') ? '.' : query.fqdn
            },
            resolveParents(query) {
              const domains = query.fqdn.split('.').reverse()
              const res = ['/dnsvs/fqdns']
              let buf = ''
              for (let i = 0; i < domains.length - 1; i++) {
                let rbuf = ''
                const f = domains[i]
                if (f !== '' && f !== '$') {
                  buf = f + '.' + buf
                  rbuf = buf
                } else {
                  rbuf = '$'
                }
                res.unshift({text: (rbuf === '$') ? '.' : rbuf, to: '/dnsvs/fqdns/' + rbuf})
              }
              return res
            }
          }
        },
        {
          path: 'fqdns/:fqdn/records',
          name: 'fqdn_records',
          component: () => import('./views/dnsvs/FQDNRecords.vue'),
          meta: {
            resolveName: function () {
              return i18n.tc('system.record', 2)
            },
            resolveParents: function (query) {
              return [`/dnsvs/fqdns/${query.fqdn}`]
            }
          }
        },
        {
          path: 'fqdns/:fqdn/references',
          name: 'fqdn_references',
          component: () => import('./views/dnsvs/FQDNReferences.vue'),
          meta: {
            resolveName: function () {
              return i18n.t('system.routing.references_to_fqdn')
            },
            resolveParents: function (query) {
              return [`/dnsvs/fqdns/${query.fqdn}`]
            }
          }
        },
        {
          path: 'bcds',
          name: 'bcds',
          component: () => import('./views/dnsvs/DNSVSEntry.vue'),
          meta: {
            resolveName: function () {
              return i18n.tc('system.bcd', 2)
            }
          }
        },
        {
          path: 'ips/:ip/records',
          name: 'ip_records',
          component: () => import('./views/dnsvs/IPRecords.vue'),
          meta: {
            resolveName: function () {
              return i18n.t('system.routing.records_to_ip')
            }
          }
        },
        {
          path: 'null_records',
          name: 'null_recs',
          component: () => import('./views/dnsvs/DNSVSEntry.vue'),
          meta: {
            resolveName: function () {
              return i18n.t('components.dnsvs_entry.null_records')
            }
          }
        },
      ]
    },
    {path: '/dnsvs/ranges', redirect: '/dnsvs/bcds'},
    {
      path: '/:pathMatch(.*)*',
      name: 'invalid_url',
      component: () => import('./views/PageNotFound.vue'),
      meta: {
        resolveName: function () {
          return i18n.t('system.invalid_url')
        }
      }
    },
  ]
})
