<template>
  <b-row class="justify-content-md-center">
    <b-col :md="block ? null : 'auto'" class="mb-0" v-if="totalRows > perPage && perPage !== 0">
      <b-pagination :total-rows="totalRows" v-model="current_page_mutable" :per-page="perPage" first-number align="fill"
                    last-number class="shadow" v-if="perPage > 0"/>
    </b-col>
    <b-col :md="block ? null : 'auto'" class="mb-0 pb-3">
      <b-input-group class="shadow flex-nowrap">
        <b-form-select v-model="per_page_mutable" :options="translated_perPageOptions"/>
        <template #append>
          <b-input-group-text>{{ $t('components.paginator.of') }} {{ totalRows }}</b-input-group-text>
        </template>
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'PaginatorComponent',
  props: {
    value: {
      default() {
        return null
      }
    },
    currentPage: {
      default() {
        return 1
      }
    },
    perPage: {
      default() {
        return 25
      }
    },
    totalRows:
      {
        required: true,
        default() {
          return 0
        }
      },
    perPageOptions: {
      default() {
        return [
          5,
          25,
          50,
          100,
          500,
          { value: 0, translation_key: 'components.paginator.all' }
        ]
      }
    },
    block: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    current_page_mutable: {
      get() {
        return this.value || this.currentPage
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('current-page', val)
      }
    },
    per_page_mutable: {
      get() {
        return this.perPage
      },
      set(val) {
        if (val === this.perPage) return // no change, no update, keep this or everything breaks. PLEASE FOR THE LOVE OF GOD, DO NOT REMOVE THIS LINE
        const prev_top_item = (this.currentPage - 1) * this.perPage
        this.$emit('per-page', val)
        this.$nextTick(() => {
          this.$emit('current-page', (Math.floor(prev_top_item / this.perPage) + 1) || 1)
        })
      }
    },
    translated_perPageOptions() {
      // Iterate over the list of options
      const copy_perPage = JSON.parse(JSON.stringify(this.perPageOptions))
      for (const copyPerPage_element of copy_perPage) {
        // Check if the item is an object to apply translations to
        if (typeof copyPerPage_element === 'object') {
          if (Object.hasOwn(copyPerPage_element, 'translation_key')) {
            // Generate the text of the item dynamically
            copyPerPage_element.text = this.$t(copyPerPage_element.translation_key)
          }
        }
      }
      return copy_perPage
    }
  }
}
</script>

<style scoped>

</style>
