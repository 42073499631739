export default {
  parse_date(input, format) {
    format = format || 'YYYY-MM-DD hh:mm:ss.ms'
    const parts = input.match(/(\d+)/g)
    let i = 0
    const fmt = {}
    format.replace(/(YYYY|DD|MM|hh|mm|ss|ms)/g, (part) => {
      fmt[part] = i++
    })
    return new Date(parts[fmt.YYYY], parts[fmt.MM] - 1, parts[fmt.DD], parts[fmt.hh], parts[fmt.mm], parts[fmt.ss], parts[fmt.ms])
  },
  format_date(date) {
    return new Intl.DateTimeFormat([JSON.parse(window.localStorage.getItem('locale') || '"de-DE"').split('_')[0], 'en-DK'], {
      dateStyle: 'medium',
      timeStyle: 'long'
    }).format(date)
  }
}
