import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Axios from 'axios'
import SystemInfoService from './api-services/system_info.service'
import DataTypeService from './api-services.gen/wapi.data_type'
import BootstrapVue from 'bootstrap-vue'
import titleMixin from './mixins/title_mixin'
import isMobileMixin from './mixins/is_mobile_mixin'
import breakpointsMixin from './mixins/breakpoints_mixin'
import otLangAttrMixin from './mixins/ot_lang_attr_mixin'
import {NetvsVuex, watchedMutations} from './vuex'

import './prototypes'
import './icons/icons'
import i18n from './i18n'

import vuexUndoRedo from './mixins/vuex-undo-redo'

import APIUtil from './util/apiutil'
import {EventBus} from '@/eventbus'
import NetvsConfig from '../netvs.config'

Vue.mixin(titleMixin)
Vue.mixin(isMobileMixin)
Vue.mixin(breakpointsMixin)
Vue.mixin(vuexUndoRedo)
Vue.mixin(otLangAttrMixin)
Vue.use(BootstrapVue)

async function init() {
  const store = NetvsVuex
  let init_fail = false
  let init_fail_info = null
  let alerts = []
  const stored_token = JSON.parse(window.localStorage.getItem('token')) || null

  try {
    alerts = (await SystemInfoService.getAlerts()).data
  } catch {
    // Silent.
  }

  store.commit('login', {token: stored_token})
  await store.dispatch('refresh_session_info')

  let data_types
  try {
    data_types = APIUtil.dict_by_value_of_array((await DataTypeService.list({}, {})).data[0], 'name')
  } catch (e) {
    data_types = null
    init_fail = true
    if ((typeof e.response.data === 'object' || e.response.data instanceof Object) && 'exception' in e.response.data) {
      init_fail_info = e.response.data.exception
    }
  }

  Vue.config.errorHandler = function (err, vm, info) {
    // handle error
    // `info` is a Vue-specific error info, e.g. which lifecycle hook
    // the error was found in. Only available in 2.2.0+
    // alert(err, info)
    window.console.debug('vue-error handler triggered')
    window.console.log(err, info)
    EventBus.$emit('error', err)
  }
  window.onerror = function (message, source, lineno, colno, error) {
    window.console.debug('window-error handler triggered')
    window.console.log(message, source, lineno, colno, error)
    EventBus.$emit('error', error)
  }
  window.addEventListener('unhandledrejection', function (event) {
    window.console.debug('unhandledrejection handler triggered')
    EventBus.$emit('error', event.reason)
  })
  Axios.interceptors.response.use(
    function (response) {
      if ('netdb-transaction-state' in response.headers) {
        if (response.headers['netdb-transaction-state'] === 'rolled_back') {
          window.console.debug('rollback detetected')
          if (response.headers['netdb-transaction-access-mode'] !== 'read_only') {
            if (!response.request.responseURL.includes('dry_mode=true')) {
              EventBus.$emit('rollback')
            }
          }
        }
      }
      return response
    },
    async function (error) {
      // handle error
      if (error.response) {
        var stat = error.response.status
        let is_logout = false
        try {
          is_logout = error.config.method.toLowerCase() === 'post' && JSON.parse(error.config.data)[0].idx === 'doLogoutRequest'
        } catch (e) {
          // Do nothing
        }
        if (is_logout) {
          return Promise.reject(error)
        }
        try {
          switch (stat) {
            case 401:
              await store.dispatch('doLogout')
              EventBus.$emit('logout')
              if (router.currentRoute.path !== '/login') {
                await router.push('/login')
              }
              break
            case 403:
              await store.dispatch('doLogout')
              EventBus.$emit('logout')
              if (router.currentRoute.path !== '/login') {
                await router.push('/login')
              }
              break
            case 400:
              if ('exception' in error.response.data) {
                if (error.response.data.exception.error_type.code === -20010 && error.response.data.exception.error.code === 4) {
                  window.console.debug('user locked')
                  EventBus.$emit('user-locked')
                }
                if (error.response.data.exception.error_type.name === 'authentication_error') {
                  window.console.debug('auth_rej')
                  await store.dispatch('doLogout')
                  if (router.currentRoute.path !== '/login') {
                    await router.push('/login')
                  }
                }
              }
              break
            default:
              break
          }
        } catch (f) {
          window.console.info('silently failed navigation error (probably by calling it in parallel)')
          window.console.info(f)
        }
      } else {
        switch (stat) {
          case 401:
            EventBus.$emit('logout')
            await store.dispatch('doLogout')
            await router.push('/login')
            break
          default:
            // alert(error)
            window.console.debug(error)
            break
        }
      }
      return Promise.reject(error)
    })
  Vue.config.productionTip = false
  Axios.defaults.baseURL = window.location.protocol + '//' + window.location.host + '/'

  const sysinfo = await SystemInfoService.getAll()
  let api_version = null
  try {
    const api_version_index = (await SystemInfoService.getAPIVersionIndex()).data[0]
    for (const ver of api_version_index) {
      if (`${ver.major}.${ver.minor}` === NetvsConfig.NETDB_API_VERSION) {
        api_version = ver.numeric
        break
      }
    }
    if (api_version === null) {
      init_fail = true
      init_fail_info = 'No compatible NETDB-API version found in backend!'
    }
  } catch (e) {
    init_fail = true
    init_fail_info = 'No compatible NETDB-API version found in backend!'
  }
  const sysinfo_dat = sysinfo.data
  const mods_by_name = {}
  for (let i = 0; i < sysinfo_dat.mods.length; i++) {
    mods_by_name[sysinfo_dat.mods[i].name] = sysinfo_dat.mods[i]
  }
  window.console.debug('Populating initial vuex state...')
  store.commit('populateInitState', {
    alerts: alerts,
    init_fail: init_fail,
    init_fail_info: init_fail_info,
    data_types: data_types,
    sysinfo: sysinfo_dat,
    sysinfo_mods_by_name: mods_by_name,
    current_api_version: api_version,
  })

  // Load the current loaded value of i18n
  i18n.locale = store.state.locale
  const v = new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
    beforeCreate: function () {
    }
  })
  v.$mount('#app')
  if (v.$store) {
    v.$store.subscribe((mutation, state) => {
      if (watchedMutations.indexOf(mutation.type) >= 0) {
        v.$store.commit('pushUndoRedoDone', mutation)
        if (state.undo_redo_new_mutaion) {
          window.console.debug('New mutation')
          v.$store.commit('clearUndoRedoUndone')
        }
      }
    })
  }
}

// noinspection JSIgnoredPromiseFromCall
init()
