// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'is_svc', 'sub_id', 'svc_id', 'kit_status', 'login_name', 'description', 'do_copy_roles', 'is_adm_locked', 'allow_data_manipulation']}
  },
  create (config, {is_svc_new = false, sub_id_new, svc_id_new, kit_status_new, login_name_new, description_new, do_copy_roles_new = true, is_adm_locked_new, allow_data_manipulation_new = true}) {
    const params = { 'new': { 'is_svc': is_svc_new, 'sub_id': sub_id_new, 'svc_id': svc_id_new, 'kit_status': kit_status_new, 'login_name': login_name_new, 'description': description_new, 'do_copy_roles': do_copy_roles_new, 'is_adm_locked': is_adm_locked_new, 'allow_data_manipulation': allow_data_manipulation_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'login_name'], 'new': [ ]}
  },
  delete (config, {login_name_old}) {
    const params = { 'new': { }, 'old': { 'login_name': login_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, is_own, is_sub, is_svc, sub_id, svc_id, is_self, gpk_list, kit_status, login_name, fetch_limit, sub_id_list, svc_id_list, email_regexp, fetch_offset, is_deletable, is_adm_locked, sub_id_regexp, svc_id_regexp, login_name_list, login_name_regexp, filter_params_dict, full_name_regexp_ic, last_name_regexp_ic, sorting_params_list, first_name_regexp_ic, parent_login_name_list, allow_data_manipulation }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'is_own': is_own, 'is_sub': is_sub, 'is_svc': is_svc, 'sub_id': sub_id, 'svc_id': svc_id, 'is_self': is_self, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'kit_status': kit_status, 'login_name': login_name, 'fetch_limit': fetch_limit, 'sub_id_list': (sub_id_list === null) ? null : JSON.stringify(sub_id_list), 'svc_id_list': (svc_id_list === null) ? null : JSON.stringify(svc_id_list), 'email_regexp': email_regexp, 'fetch_offset': fetch_offset, 'is_deletable': is_deletable, 'is_adm_locked': is_adm_locked, 'sub_id_regexp': sub_id_regexp, 'svc_id_regexp': svc_id_regexp, 'login_name_list': (login_name_list === null) ? null : JSON.stringify(login_name_list), 'login_name_regexp': login_name_regexp, 'filter_params_dict': filter_params_dict, 'full_name_regexp_ic': full_name_regexp_ic, 'last_name_regexp_ic': last_name_regexp_ic, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list), 'first_name_regexp_ic': first_name_regexp_ic, 'parent_login_name_list': (parent_login_name_list === null) ? null : JSON.stringify(parent_login_name_list), 'allow_data_manipulation': allow_data_manipulation})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'login_name'], 'new': [ 'sub_id', 'svc_id', 'kit_status', 'login_name', 'description', 'is_adm_locked', 'allow_data_manipulation']}
  },
  update (config, {sub_id_new, svc_id_new, kit_status_new, login_name_old, login_name_new, description_new, is_adm_locked_new, allow_data_manipulation_new}) {
    const params = { 'new': { 'sub_id': sub_id_new, 'svc_id': svc_id_new, 'kit_status': kit_status_new, 'login_name': login_name_new, 'description': description_new, 'is_adm_locked': is_adm_locked_new, 'allow_data_manipulation': allow_data_manipulation_new}, 'old': { 'login_name': login_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
