<template>
  <div class="links">
    <div v-for="(group, key) in links" :key="key" class="my-2">
      <div v-for="link in group" :key="key + link.label + link.address">
        <template v-if="!('visible' in link) || link.visible()">
          <div class="link-button-wrapper">
            <b-button :to="link.address.startsWith('https:/') || link.address.startsWith('http:/') ? undefined : link.address"
                      :href="link.address.startsWith('https:/') || link.address.startsWith('http:/') ? link.address : undefined"
                      :target="link.address.startsWith('https:/') || link.address.startsWith('http:/') ? '_blank' : undefined"
                      :id="'navigation-link-' + key + link.label + id"
                      :class="{'active': link.has_sub_pages? $route.path.startsWith(link.address):$route.path === link.address}"
                      @click="hidePopovers?$root.$emit('bv::toggle::collapse', 'navbarNavDropdown'):undefined"
                      @mouseover="link.on_mouse_over ? link.on_mouse_over() : null"
                      @mouseout="link.on_mouse_out ? link.on_mouse_out() : null"
                      @hover.passive="null"
                      class="link-button">
              <span class="navigation-icon"><netvs-icon size="lg" :icon="link.icon"></netvs-icon></span>
              <span class="icon-text-spacer"/>
              {{ link.label }}
            </b-button>
            <b-popover :target="'navigation-link-' + key + link.label + id" triggers="hover focus"
                       v-if="!$store.state.show_sidebar_left && !hidePopovers"
                       placement="right" boundary="window" custom-class="popover-wide"
                       :content="link.label"/>
          </div>
        </template>
      </div>
      <hr class="mt-2">
    </div>
    <img alt="Clippy" src="@/assets/img/clippy.png" class="clippy" :class="{show: hovering_help}"/>
  </div>
</template>

<script>

import apiutil from '@/util/apiutil'
import i18n from '@/i18n'

export default {
  name: 'LeftNavigation',
  computed: {
    links() {
      const self = this
      return {
        systems: [
          {
            has_sub_pages: false,
            label: this.$t('system.home'),
            address: '/home',
            icon: 'home',
          },
          {
            has_sub_pages: true,
            label: this.$t('system.dnsvs'),
            address: '/dnsvs/bcds',
            icon: 'dnsvs',
            visible() {
              return self.$store.state.sysinfo.js_mods.includes('dnsvs') && self.$store.state.token
            }
          },
          {
            has_sub_pages: true,
            label: this.$t('system.macauth'),
            address: '/macauth',
            icon: 'macauth',
            visible() {
              return self.$store.state.sysinfo.js_mods.includes('macauth') && self.$store.state.token
            }
          },
          {
            has_sub_pages: true,
            label: this.$t('system.dhcp_leases'),
            address: '/dhcp-leases/',
            icon: 'dhcp_leases',
            visible() {
              return self.$store.state.sysinfo.js_mods.includes('dhcp_leases')
            }
          },
          {
            has_sub_pages: true,
            label: this.$t('system.natvs') + '+',
            address: self.$store.state.sysinfo.netdb_admin_base + '/~netadmin/natvs/user/wrapper.cgi/',
            icon: 'natvs'
          },
          {
            has_sub_pages: true,
            label: this.$t('system.netdoc'),
            address: '/netdoc',
            icon: 'netdoc',
            visible() {
              return self.$store.state.sysinfo.js_mods.includes('netdoc') && self.$store.state.token
            }
          },
          {
            has_sub_pages: false,
            label: this.$t('system.netdoc'),
            address: self.$store.state.sysinfo.netdb_admin_base + '/~netadmin/netdoc/user/wrapper.cgi/',
            icon: 'netdoc',
            visible() {
              return !self.$store.state.sysinfo.js_mods.includes('netdoc')
            }
          }
        ],
        organizations: [
          {
            has_sub_pages: true,
            label: this.$tc('system.organizational_unit', 2),
            address: '/org/ou',
            icon: 'orgs',
            visible() {
              return self.$store.state.token
            }
          },
          {
            has_sub_pages: false,
            label: this.$tc('system.oe_type', 2),
            address: '/org/type',
            icon: 'org_types',
            visible() {
              return apiutil.checkPermission(self.$store.state, 'org.rw_oet')
            }
          },
          {
            has_sub_pages: true,
            label: this.$tc('system.group', 2),
            address: '/cntl/groups',
            icon: 'groups',
            visible() {
              return self.$store.state.token
            }
          },
          {
            has_sub_pages: true,
            label: i18n.tc('components.svc_card.svc_account', 2),
            address: '/cntl/svc-accounts',
            icon: 'svc_account',
            visible() {
              return self.$store.state.token
            }
          }
        ],
        tools: [
          {
            has_sub_pages: false,
            label: this.$t('system.netvs_hub'),
            address: '/tools/hub',
            icon: 'community_content',
            visible() {
              return self.$store.state.token
            }
          },
          {
            has_sub_pages: false,
            label: this.$t('system.macfinder'),
            address: '/tools/macfinder',
            icon: 'macfinder',
            visible() {
              return self.$store.state.sysinfo.js_mods.includes('macfinder') && self.$store.state.token
            }
          },
          {
            has_sub_pages: false,
            label: this.$t('system.macvendor_search'),
            address: '/tools/oui_lookup',
            icon: 'macvendor_search',
            visible() {
              return self.$store.state.sysinfo.js_mods.includes('macfinder')
            }
          },
          {
            has_sub_pages: false,
            label: this.$t('system.ap_list'),
            address: '/tools/ap_list',
            icon: 'ap_list',
            visible() {
              return self.$store.state.sysinfo.js_mods.includes('ap_list') && self.$store.state.token && apiutil.checkPermission(self.$store.state, 'nd.ro:device')
            }
          },
          {
            has_sub_pages: false,
            label: this.$t('system.ip_tools'),
            address: '/tools/ip',
            icon: 'ip_tools'
          },
          {
            has_sub_pages: false,
            label: this.$t('system.ip_contact'),
            address: '/tools/ip_contact',
            icon: 'ip_contact',
            visible() {
              return self.$store.state.sysinfo.js_mods.includes('ip_contact') && self.$store.state.token
            }
          },
          {
            has_sub_pages: false,
            label: this.$t('system.domain_finder'),
            address: '/tools/domain_finder',
            icon: 'domain_finder',
            visible() {
              return self.$store.state.sysinfo.js_mods.includes('domainfinder') && self.$store.state.token
            }
          }
        ],
        requests: [
          {
            has_sub_pages: false,
            label: this.$tc('system.request', 2),
            address: '/requests',
            icon: 'requests'
          }
        ],
        misc: [
          {
            has_sub_pages: false,
            label: this.$t('system.help') + '/' + this.$t('system.documentation'),
            address: this.$store.state.sysinfo.host_oper_mode.is_prod ? 'https://doku.netdb.scc.kit.edu/' : `https://doku.netdb-${this.$store.state.sysinfo.host_oper_mode.mode}.scc.kit.edu/`,
            icon: 'documentation_help',
            on_mouse_over: () => { this.hovering_help = true },
            on_mouse_out: () => { this.hovering_help = false }
          },
          {
            has_sub_pages: false,
            label: this.$t('system.api'),
            address: '/swagger',
            icon: 'api'
          },
          {
            has_sub_pages: false,
            label: this.$t('components.footer.netvs_matrix_community'),
            address: 'https://matrix.to/#/#netvs-community:kit.edu',
            icon: 'chat'
          },
          {
            has_sub_pages: false,
            label: this.$t('system.report_error'),
            address: 'https://gitlab.kit.edu/scc-net/netvs/netvs-core/issues',
            icon: 'bug'
          }
        ]
      }
    }
  },
  data() {
    return {
      id: null,
      hovering_help: false
    }
  },
  props: {
    hidePopovers: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.id = this._uid
  }
}
</script>

<style lang="scss" scoped>

@import '../assets/css/variables.scss';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

.link-button-wrapper {
  width: 100%;
  padding: $sidenavbar-button-margin;
}

.link-button,
.collapse-button {
  color: $sidenavbar-button-color;
}

.link-button {
  width: 100%;
  min-width: $left-sidebar-hidden-width - 7px;
  text-overflow: fade;
  overflow-x: hidden;
  overflow-y: hidden;
  border: none;
  border-radius: $border-radius;
  height: $left-sidebar-hidden-width / 1.5;
  line-height: $left-sidebar-hidden-width / 1.5;
  background: none;
  text-align: left;
  padding: 0;
}

.link-button:hover,
.link-button.active,
.link-button:focus {
  background-color: $sidenavbar-active-hover-color !important;
  color: $sidenavbar-button-color !important;
}

.link-button.active {
  font-weight: bold;
}

.icon-text-spacer {
  display: inline-block;
  width: 0;
  transition: width 0.15s ease-in-out;
}

.link-button:hover > .icon-text-spacer,
.link-button:focus > .icon-text-spacer {
  width: 0.5em;
}

.navigation-icon {
  width: $left-sidebar-hidden-width - 7px;
  text-align: center;
  display: inline-block;
  transform: scale(1);
  transition: transform 0.15s ease-in-out;
}

.link-button:hover > .navigation-icon,
.link-button.active > .navigation-icon,
.link-button:focus > .navigation-icon {
  transform: scale(1.2);
}

.link-button.active > .navigation-icon {
  color: $primary;
}

.link-button > .sidebar-icon {
  margin: -$sidenavbar-button-margin;
}

.thicc {
  min-width: $left-sidebar-width;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
}

.clippy {
  height: 25vh;
  position: absolute;
  left: 12vw;
  bottom: -2vh;
  transform: translateY(200%);
  transition: transform .5s, opacity .5s;
  z-index: 42069;
  opacity: 0;
}

.clippy.show {
  transform: translateY(0);
  transition: transform ease 5s, opacity 5s;
  opacity: 1;
}
</style>
