<template>
  <HybridDBEditor v-if="template" @commited_wrapper="commit" @ready="ready"
                  :object_function="object_function"
                  :object_fq_name="object_fq_name"
                  :input_reducer="input_reducer"
                  :template="template"
                  :modal_id="modal_id"
                  :non_optionals="non_optionals"
                  :nullable_non_expert="nullable_non_expert"
                  :object_title="object_title"
                  :old_data="old_data"
                  :old_uuid="old_uuid"
                  :non_optionals_order="non_optionals_order"
                  :presets="presets"
                  :preview_fields="preview_fields"
                  :show_result="show_result" :only_emit_ta="true"/>
</template>

<script>

import HybridDBEditor from '@/components/db-editor/HybridDBEditor.vue'

const props = JSON.parse(JSON.stringify(HybridDBEditor.props))
props.template = {
  type: Object,
  required: true,
}
props.prepend = {
  type: Boolean,
  required: false,
  default() {
    return true
  }
}
props.object_title = {
  type: String,
  required: false,
  default() {
    return undefined
  }
}
delete props.only_emit_ta
const exp = {
  name: 'BundledDBEditor',
  components: { HybridDBEditor },
  props: props,
  methods: {
    commit(data) {
      data.ta_type = 'db_editor_bundle_' + (this.prepend ? 'prepend' : 'append')
      data.content.template = this.template
      window.console.debug('BundledDBEditor commit', data)
      this.$store.commit('addTransactionElement', data)
    },
    ready() {
      this.$emit('ready')
    }
  }
}
window.console.debug(exp)
export default exp
</script>

<style scoped>

</style>
