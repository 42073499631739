<template>
  <b-alert show variant="danger" class="mt-3"
           v-if="$store.state.transaction_result && $store.state.transaction_result.type === 'error'">
    <netvs-icon class="mr-1" icon="transaction_error"/>
    <b>{{ $store.state.transaction_result.error.exception.error.description }}</b>
    <p v-if="$store.state.transaction_result.error.exception.hint">
      {{ $store.state.transaction_result.error.exception.hint }}</p>
    <p v-if="specialInfo">
      {{ $t(specialInfo.title) }}<br/>{{ specialInfo.message }}
    </p>
    <b-link class="d-block mt-2" v-b-toggle.collapse-err-details>{{
        $tc('components.ta_card_error_display.details', 1)
      }}
    </b-link>
    <b-collapse id="collapse-err-details">
      {{ $store.state.transaction_result.error.exception.error.details }}
    </b-collapse>
  </b-alert>
</template>

<script>
export default {
  name: 'TACardErrorDisplay',
  computed: {
    specialInfo() {
      let tar = this.$store.state.transaction_result
      if (!tar || !('error' in tar)) {
        return null
      }
      tar = tar.error.exception
      for (const trace of tar.traceback) {
        if (trace.function === 'cntl.do_perm_check') {
          if ('cntl.error.required_roles' in trace.param) {
            const roles = []
            for (const role of trace.param['cntl.error.required_roles']) {
              roles.push(role.value.replaceAll(/['()]/g, ''))
            }
            return {
              title: 'components.ta_card_error_display.perm_check_required_roles',
              message: roles.join(', ')
            }
          }
        } else if (trace.function === 'eh.foreign_key_violation') {
          const child_found = trace.param['wapi.exception.fk_violation_child_found'][0].value
          // const parent_no_found = trace.param['eh.fk_violation.parent_not_found'][0].value
          if (child_found) {
            return { title: 'components.ta_card_error_display.fk_violation_child_found', message: `${trace.param['wapi.exception.fk_ot_fq_name'][0].value}.${trace.param['wapi.exception.foreign_key_attr_names'][0].value.join(',')}` }
          }
        }
      }
      if (tar.error_type.code === -20010) {
        return { title: 'components.ta_card_error_display.perm_trubleshoot', message: ''}
      }
      return null
    }
  },
}
</script>

<style scoped>

</style>
