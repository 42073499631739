<template>
  <div class="side-navbar d-flex flex-column" id="sideNavbar">
    <div>
      <button v-if="!$store.state.show_sidebar_left" class="collapse-button"
              @click.stop="$store.commit('showSidebarLeft', !$store.state.show_sidebar_left)">
        <span class="sidebar-icon"><netvs-icon icon="sidebar_expand_to_right"></netvs-icon></span>
      </button>
      <button v-if="$store.state.show_sidebar_left" class="collapse-button thicc"
              @click.stop="$store.commit('showSidebarLeft', !$store.state.show_sidebar_left)">
        <span class="sidebar-icon"><netvs-icon icon="sidebar_collapse_to_left"></netvs-icon></span>
        {{ Math.random() > .99 ? $tc('system.collapse', 2) : $tc('system.collapse', 1) }}
      </button>
      <hr>
    </div>

    <Navigation/>

    <div class="mt-auto">
      <hr>
      <button v-if="!$store.state.show_sidebar_left" class="collapse-button"
              @click.stop="$store.commit('showSidebarLeft', !$store.state.show_sidebar_left)">
        <span class="sidebar-icon"><netvs-icon icon="sidebar_expand_to_right"></netvs-icon></span>
      </button>
      <button v-if="$store.state.show_sidebar_left" class="collapse-button thicc"
              @click.stop="$store.commit('showSidebarLeft', !$store.state.show_sidebar_left)">
        <span class="sidebar-icon"><netvs-icon icon="sidebar_collapse_to_left"></netvs-icon></span>
        {{ Math.random() > .99 ? $tc('system.collapse', 2) : $tc('system.collapse', 1) }}
      </button>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation'

export default {
  name: 'SideNavbar',
  components: { Navigation }
}
</script>

<style lang="scss" scoped>

@import '../assets/css/variables.scss';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

.side-navbar {
  text-overflow: fade;
  overflow-x: hidden;
  height: 100%;
  white-space: nowrap;
}

.collapse-button {
  width: 100%;
  border: none;
  height: $left-sidebar-hidden-width;
  background: none;
  text-align: left;
  padding: 0;
  color: $sidenavbar-button-color;
}

.collapse-button:hover {
  background-color: $sidenavbar-active-hover-color;
}

.sidebar-icon {
  width: $left-sidebar-hidden-width;
  text-align: center;
  display: inline-block;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
