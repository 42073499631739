<template>
  <!-- TODO: Add a label that hides on bigger screens -->
  <!-- Probably best done in the parent component -->
  <b-nav-item-dropdown :title="$t('components.locale_switcher.language_settings')" right>
    <template v-slot:button-content>
      <netvs-icon icon="language" :class="{wideIcon: navbar_collapse_open}"/>
      {{ current_language_full_name }}
    </template>
    <b-dropdown-item v-for="(language) in available_languages" :key="language.code" :value="language.code" @click="current_language = language.code">
        <span v-if="current_language === language.code"><b>{{ language.flag + ' ' + language.full_name }}</b></span>
        <span v-else>{{ language.flag + ' ' + language.full_name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  name: 'LocaleSwitcher',
  props: {
    navbar_collapse_open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      languages: [
        {
          code: 'en',
          full_name: 'English',
          flag: '🇬🇧'
        },
        {
          code: 'de',
          full_name: 'Deutsch',
          flag: '🇩🇪'
        },
        {
          code: 'de_sw',
          full_name: 'THE LÄND',
          flag: '🏴󠁤󠁥󠁢󠁷󠁿'
        }
      ]
    }
  },
  computed: {
    current_language: {
      get: function () {
        return this.$store.state.locale
      },
      set: function (language) {
        this.$store.commit('updateLocale', language)
      }
    },
    available_languages() {
      return this.languages.filter(language => language.code !== 'de_sw' || !this.$store.state.spec_ready)
    },
    current_language_full_name() {
      return this.languages.find(language => language.code === this.current_language).full_name
    }
  }
}
</script>
<style scoped lang="scss">
@import '../assets/css/variables.scss';

.wideIcon {
  width: $left-sidebar-hidden-width - 7px;
  text-align: center;
  display: inline-block;
}
</style>
